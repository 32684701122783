import React, { useState, useReducer, useEffect } from 'react';
import { Select, Form, InputNumber, Col, Row, Button, Divider, Typography, Input, Upload, message, Table, DatePicker } from 'antd';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface'

import { getUsers } from '../../utils/users';

import axios from 'axios';

const azure_function_url = process.env.AZURE_FUNCTION_URL;

export const CreateDeal: React.FC<any> = (parent) => {
    const [form] = Form.useForm();
    const [defaultFileList, setDefaultFileList] = useState<any>({data: []});
    const [isLoading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [users, setUsers] = useState<any[]>();

    // const [balanceData, setBalanceData] = useState<any>({});
    // const [poolData, setPoolData] = useState<any>({});
    // const [currency, setCurrency] = useState<string>("usdc");

    useEffect(() => {
        getUsers().then(setUsers)
        // setUsers()
    //     axios.get(
    //         `${azure_function_url}/LayerC?command=balance&chainid=${parent.chainId}&currency=${parent.currency}&obligor=${parent.obligor}`, 
    //         {
    //             headers: {
    //               'Authorization': parent.bearerToken
    //             }
    //         }).then(x => { 
    //             const data = x.data.balances;

    //             setPoolData({ decimals: data.decimals, lockedIn: data.lockedIn, lockedOut: data.lockedOut, locked: data.lockedIn.concat(data.lockedOut).map((x: any, i: number) => { x.key=i; return x }) });

    //             setBalanceData({                         
    //                 balance: BigInt(data.balance) / BigInt(data.decimals),
    //                 privateBalance: BigInt(data.privateBalance) / BigInt(data.decimals), 
    //                 lockedIn: BigInt(data.lockedIn.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? currentValue.amount : BigInt(0)), BigInt(0))) / BigInt(data.decimals), 
    //                 lockedOut: BigInt(data.lockedOut.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? currentValue.amount : BigInt(0)), BigInt(0))) / BigInt(data.decimals)
    //             });
    //         });
    },[]);



    let myClonedArray:any[] = [];

    const onSend = async (values: any) => {   
        setLoading(true);

        Object.keys(values).forEach(key => { try{ values[key] = values[key].unix(); } catch{} });

        console.log(values);
        
        axios.post(
        `${azure_function_url}/LayerC?command=create_deal&chainid=${parent.chainId}&currency=${parent.currency}&obligor=${values.counterpart}`, 
        {
            counterpart: values.counterpart,
            data: values,
            payments: {
                amount: values.initial * parent.poolData.decimals,
                terms: [
                    { unlock_receiver: Math.floor(new Date().getTime() / 1000) - 1000 }
                ]
            }
        },
        {
            headers: {
                'Authorization': parent.bearerToken
            }
        }).then(x => console.log(BigInt(x.data).toString()));
        
        form.resetFields();
        parent.closeModal();
        setLoading(false);
        setDefaultFileList({data: []});
    };

    const uploadFile = async (options: UploadRequestOption<any>) => {
        // eslint-disable-next-line
        const { onSuccess, onError, file, onProgress } = options;

        const buffer = await (file as File).arrayBuffer();
        const bytes = new Uint8Array(buffer);
        const b64 = Buffer.from(bytes).toString('base64');

        if(onSuccess){
            myClonedArray.push({ name: (file as File).name, data: b64, created: Date.now() })
            setDefaultFileList({ data: myClonedArray });
        
            onSuccess("Ok");
        }
    };

    const removeFile = async (name: string) => {
        myClonedArray = [];
        defaultFileList.data.forEach((val: any) => {
            if(val.name !== name) myClonedArray.push(Object.assign({}, val))
        }); 
        setDefaultFileList({ data: myClonedArray });
    }

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        customRequest: async (x: any) => { 
            myClonedArray = [];
            if(defaultFileList.data)
                defaultFileList.data.forEach((val: any) => myClonedArray.push(Object.assign({}, val))); 
            await uploadFile(x);
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                forceUpdate();
            } 
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    return (
        <>
            <Form
                name="create"
                form={form}
                onFinish={onSend}
                initialValues={{}}
                labelCol={{ span: 8 }}
            >
                {/* <Divider orientation="left"></Divider>
                <Paragraph style={{textAlign:'left'}}>
                Create a deal and add collateral in the form of files.
                </Paragraph> */}
                <Divider orientation="left"></Divider>
                {/* <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Group" 
                            name="group"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Search to Select"
                                optionFilterProp="label"
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={users?.map(x=>{ return {value: x.wallet, label: x.name }})}
                            />
                        </Form.Item>
                    </Col>
                </Row> */}
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Counterpart" 
                            name="counterpart"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Search to Select"
                                optionFilterProp="label"
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={users?.map(x=>{ return {value: x.wallet, label: x.name }})}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Information</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Name" 
                            name="name"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Description" 
                            name="description"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Notional" 
                            name="notional"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 

                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row> */}
                <Divider orientation="left">Payment Terms</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Amount" 
                            name="initial"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 

                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Frequency" 
                            name="freq"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            
                            <Select
                                // showSearch
                                style={{ width: '100%' }}
                                placeholder="Search to Select"
                                optionFilterProp="label"
                                defaultValue='month'
                                filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={[
                                    {
                                        value: 'week',
                                        label: 'Weekly',
                                    },
                                    {
                                        value: 'week2',
                                        label: 'Fort-nightly',
                                    },
                                    {
                                        value: 'month',
                                        label: 'Monthly',
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Bank Account</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Name" 
                            name="bank_name"
                            rules={[{ required: true, message: 'Please input bsb' }]}
                        >
                            <Input
                                min={0} 
                                style={{ width: '100%' }} 
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="BSB" 
                            name="bank_bsb"
                            rules={[{ required: true, message: 'Please input bsb' }]}
                        >
                            <Input
                               
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Account" 
                            name="bank_account"
                            rules={[{ required: true, message: 'Please input account' }]}
                        >
                            <Input
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Divider orientation="left">Payment Conditions</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Unlock Payer" 
                            name="unlock_sender"
                        >
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Unlock Payee" 
                            name="unlock_receiver"
                        >
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Expiry" 
                            name="expiry"
                        >
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Approver" 
                            name="oracle_owner"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Key" 
                            name="oracle_key"
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 

                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Value" 
                            name="oracle_value"
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 

                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Files</Divider>
                {
                    defaultFileList.data.length > 0 ?
                    <Table 
                        dataSource={defaultFileList.data.map((x:any, i:number) => { x.key=i; return x})}
                        pagination={{hideOnSinglePage: true}}
                    >
                        <Column
                            title="Name"
                            key="name"
                            render={(_: any, record: any) => (
                                record.name
                            )}
                        />
                        <Column
                            title="Delete"
                            key="delete"
                            render={(_: any, record: any) => (
                                <Button 
                                type="default" 
                                block
                                onClick={async ()=> { removeFile(record.name); } }
                                >
                                    <DeleteOutlined />
                                </Button>
                            )}
                        />
                    </Table>
                    :
                    <></>
                }
                
                <Row gutter={16}>
                    <Col span={24}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                            Strictly prohibited from uploading illicit, unlawful, illegal data or other
                            banned files.
                            </p>
                        </Dragger>
                    </Col>
                </Row> */}
                <Divider orientation="left"></Divider>
                <Form.Item>
                    <Button 
                        style={{
                            position: "absolute",
                            width: "120px",
                            left: 0,
                            transform: "translateY(-50%)"
                        }} 
                        onClick={() => { form.resetFields(); setLoading(false); parent.closeModal(); }}
                        disabled={false}
                    >
                        Cancel
                    </Button>

                    <Button 
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={!parent.poolData.decimals}
                        style={{
                            position: "absolute",
                            right: 0,
                            width: "120px",
                            transform: "translateY(-50%)"
                        }} 
                    >
                        Create
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}