import React, { useState, useEffect, useReducer } from 'react';

import { Card, Table, Space, Select, Image, Col, Row, Statistic, FloatButton, Modal, Button, Divider, DatePicker } from 'antd';
import { HomeOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { CreateDeal } from './createdeal';
import { AcceptDeal } from './commitdeal';
import { ViewDeal } from './viewdeal';
 
import axios from 'axios';

import { currencyAddresses, currencies, uxUpdateFrequency } from '../../utils/config';

const { Column } = Table;

const azure_function_url = process.env.AZURE_FUNCTION_URL;

const truncate = (str : string) => {
    if(str)
        return str.length > 20 ? str.substring(0, 10) + "..." + str.substring(str.length - 10, str.length) : str;
    else
        return str;
}

export const Assets: React.FC<any> = (parent) => {
    const [openMenu, setOpenMenu] = useState(false);//!parent.isMobile);

    const [currency, setCurrency] = useState<string>("usdc");
    const [dealOwnerData, setDealOwnerData] = useState<any[]>([]);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    
    const [notional, setNotional] = useState(BigInt(0));
    const [totalLocked, setTotalLocked] = useState(BigInt(0));

    const [deal, setDeal] = useState<{deal: any, type: number}>({deal: null, type: 0});
    // eslint-disable-next-line
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [balanceData, setBalanceData] = useState<any>({});

    const [activeFetchId, setActiveFetchId] = useState<NodeJS.Timer>();
    
    let loading = false;
    useEffect(() => {
        if(activeFetchId !== undefined)
            clearInterval(activeFetchId);

        const id = setInterval(() => {
            
            if(!loading){
                
                loading = true;
                axios.get(
                    `${azure_function_url}/LayerC?command=assets_get&chainid=${parent.chainId}&currency=${parent.currency}&groupId=${parent.groupId}`, 
                    {
                        headers: { 'Authorization': parent.bearerToken }
                    })
                    .then(x => {
                        
                        if(parent.poolData.decimals){
                            let filtered = x.data.filter((x: any) => x.denomination === currencyAddresses[parent.chainId][currency]);
                            let total_notional = filtered.reduce((acc: any, current: any) => acc + BigInt(current.notional), BigInt(0)) / BigInt(parent.poolData.decimals);
                            let total_locked = filtered.reduce((acc: any, current: any) => acc + BigInt(current.meta.total_locked), BigInt(0)) / BigInt(parent.poolData.decimals);
                            setNotional(total_notional);
                            setTotalLocked(total_locked);
                            setDealOwnerData(filtered);
                            forceUpdate();
                            
                        }
                        loading = false;
                    }).catch(x=> {loading = false;});
            }

        }, uxUpdateFrequency); 

        setActiveFetchId(id);
    },[parent.poolData.decimals, parent.groupId]);

    return (
        <>
        {dealOwnerData?.length > 0 && 
        <div style={{
            // position: 'relative', 
            // display: 'flex', 
            // justifyContent: 'center', 
            // height: 'calc(100vh - 65px)'
        }}>

            <div 
                className='custom-card' 
                style={{
                    display: openMenu && parent.isMobile ? 'none' :  undefined,
                    width:  '100%', 
                    height: '100%',
                    // marginRight: parent.isMobile ? undefined :  '55px', 
                    // marginLeft: parent.isMobile || openMenu ? undefined:  '55px',
                }}
            >
                <Card 
                    bordered={true} 
                    title={"Owner"}
                    className='custom-card'
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Table 
                        dataSource={dealOwnerData}
                        pagination={{hideOnSinglePage: true}}
                    >
                        <Column
                            title="Status"
                            key="status"
                            render={(_: any, record: any) => (
                                record.meta.cancelled_counterpart > 0 || record.meta.cancelled_owner > 0 ? 'Cancelled' : record.meta.accepted > 0 ? 'Commited' : 'Review'
                            )}
                        />
                        <Column
                            title="Name"
                            key="name"
                            render={(_: any, record: any) => (
                                record.data.name
                            )}
                        />
                        <Column
                            title="Counterpart"
                            key="counterpart"
                            render={(_: any, record: any) => (
                                truncate(record.counterpart)
                            )}
                        />
                        <Column
                            title="Target"
                            key="notional"
                            render={(_: any, record: any) => (
                                <><Image preview={false} height={15} style={{marginTop: "-2px"}} src={currencies[currency].url} /> {(BigInt(record.notional) / BigInt(parent.poolData.decimals)).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</>
                            )}
                        />
                        <Column
                            title="Created"
                            key="created"
                            render={(_: any, record: any) => (
                                <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.meta.created)} />
                            )}
                        />
                        <Column
                            title="Action"
                            key="action"
                            render={(_: any, record: any) => (
                                <Button 
                                    type="default" 
                                    block
                                    onClick={async ()=> { 
                                        if(record.accepted || record.counterpart.toLowerCase() !== parent.walletData?.address?.toLowerCase()){
                                            setDeal({deal: record, type: 2}); 
                                            forceUpdate();
                                        }
                                        else{
                                            setDeal({deal: record, type: 1}); 
                                            forceUpdate();
                                        }
                                        
                                    }}
                                >
                                    
                                    {record.accepted || record.counterpart.toLowerCase() !== parent.walletData?.address?.toLowerCase() ? 'View' : 'Commit'}
                                </Button>
                            )}
                        />
                    </Table>
                </Card>
                    
            </div>
        </div>}

        <FloatButton.Group
            type="default"
            style={{ left: 7, width: '40px' }}
            icon={<HomeOutlined />}
        >
            <FloatButton tooltip={<div>Create Agreement</div>} icon={<EditOutlined />} onClick={()=> {setCreateModalOpen(true);}} />
            {/* <FloatButton tooltip={<div>Logout</div>} icon={<LogoutOutlined />} onClick={async () => {
                // const permissions = await ethereum.request({
                //   method: 'wallet_requestPermissions',
                //   params: [{
                //     eth_accounts: {},
                //   }]
                // });
                // setWalletData({ address: null });
                // setCurrent(0);

                parent.logout();
            }}/> */}
        </FloatButton.Group>

        <Modal title="Create Agreement" closable={false} open={isCreateModalOpen} footer={null}>
            <CreateDeal 
                bearerToken={parent.bearerToken}
                tokenAddress={currencyAddresses[parent.chainId][currency]}
                chainId={parent.chainId}
                walletData={parent.walletData}
                obligor={parent.obligor}
                currency={currency}    
                poolData={parent.poolData}
                closeModal={() => {setCreateModalOpen(false);}}
            />
        </Modal>
        <Modal title="Accept Contract" closable={false} open={deal.type === 1} footer={null}>
            <AcceptDeal 
                bearerToken={parent.bearerToken}
                tokenAddress={currencyAddresses[parent.chainId][currency]}
                deal={deal.deal}
                closeModal={() => {setDeal({deal: null, type: 0});}}
            />
        </Modal>
        <Modal title="View Contract" closable={false} open={deal.type === 2} footer={null}>
            <ViewDeal 
                bearerToken={parent.bearerToken}
                tokenAddress={currencyAddresses[parent.chainId][currency]}
                deal={deal.deal}
                increase={false}
                closeModal={() => {setDeal({deal: null, type: 0});}}
            />
        </Modal>
        </>
    );
}