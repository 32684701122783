import React, { useState, useRef, useEffect, useReducer } from 'react';

import { Checkbox, Popconfirm, Form, FormInstance, Col, Row, Modal, Input, Card, Button, message  } from 'antd';
import { SaveOutlined, FormOutlined, SyncOutlined, LoadingOutlined, FullscreenOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // Quill's CSS
import deltaToMarkdown from 'quill-delta-to-markdown';
import { marked } from 'marked';
import { v4 as uuidv4 } from 'uuid';

import 'highlight.js/styles/github.css';  // Or any other style you prefer

import TurndownService from 'turndown';

import axios from 'axios';

import * as yaml from 'js-yaml';
import ideaDynamic from '../../yml/ideas/dynamic.yaml';
import moduleRAG from '../../yml/rag/module.yaml';
import instructionStrictRAG from '../../yml/rag/instruction_strict.yaml';

import { Input as SInput } from './Input';

export const Idea = ({ id, title, value, onChange, card, noborder, placeholder, disabled, handleKeyDown, agent, tags, bearerToken, user, context, idx, dream, handleDelete, loading, newCard, outputCard, handleNewCard, streamid, isMobile, checked, setChecked, setContent, save } : any) => {

    const [raw, setRaw] = useState(value);
    const [text, setText] = useState(value);
    const lastValueRef = useRef<string>(value);
    const lastTextRef = useRef<string>(value);
    const lastTitleRef = useRef<string>(title);
    const quillRef = useRef<ReactQuill>(null); // Create a ref for the Quill editor
    const [isLoadingChat, setLoadingChat] = useState({ state: false });
    const [isEditingTitle, setIsEditingTitle] = useState(false);

    const [isChecked, setIsChecked] = useState(checked);

    const ref_agent = useRef(null);
    const ref_tags = useRef(null);
    
    const [_title, setTitle] = useState(title);
    const formRef = useRef<FormInstance>(null);

    const ref_input = useRef(null);
    const messageInputRef = useRef<HTMLDivElement | null>(null);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    
    const ref_send = useRef(null);

    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setLoading] = useState(loading);
    const [flow, setFlow] = useState<string | undefined>();

    const [textChange, setTextChange] = useState(save === true);

    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    useEffect(() => {
        const handlePaste = (e: any) => {
            e.preventDefault(); // Prevent the default paste behavior
        
            // Access clipboardData from the event object
            const clipboardData = e.clipboardData;
            const pastedText = clipboardData.getData('text').replace(/\n/g, '<br\\>');

            if (quillRef.current) {
                const editor = quillRef.current.getEditor();
                const range = editor.getSelection(true);
            
                if (range) {
                    // Move the cursor to the end of the selection
                      editor.setSelection(range.index, range.length);
            
                    // Check if the 'insertHtml' method is available (not standard in Quill)
                    if (editor.clipboard && typeof editor.clipboard.dangerouslyPasteHTML === 'function') {
                        // editor.clipboard.dangerouslyPasteHTML(range.index, html);
                        editor.clipboard.dangerouslyPasteHTML(range.index, marked(pastedText));

                    } else {
                        // Fallback: Insert the HTML as plain text
                        // editor.insertText(range.index, html, 'user');
                        editor.insertText(range.index, pastedText, 'user');
                    }

                    const contents = editor.getContents(); // Get the editor content in Delta format
                    const markdown = deltaToMarkdown.deltaToMarkdown(contents.ops); // Convert Delta
             
                    setText(marked(markdown))
                }
            }
        };

        if (quillRef.current) {
            const editor = quillRef.current.getEditor();
            editor.root.addEventListener('paste', handlePaste);
            if(handleKeyDown)
                editor.root.addEventListener('keydown', handleKeyDown);
        }
        
        return () => {
            if (quillRef.current) {
                // Clean up the event listener when the component unmounts or the ref changes
                const editor = quillRef.current.getEditor();
                editor.root.removeEventListener('paste', handlePaste);

                if(handleKeyDown)
                    editor.root.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [quillRef, agent, tags, value]); // Ensure effect runs again if quillRef changes

    const handleChange = (content: string, delta: any, source: string, editor: any) => {
        if (source === 'user') {
          const markdown = deltaToMarkdown.deltaToMarkdown(editor.getContents().ops);
          setRaw(markdown);
          onChange?.(markdown);
          lastValueRef.current = markdown;
          setText(marked(content))
          if(setContent){
            const turndownService = new TurndownService();
            setContent(turndownService.turndown(content).replace(/\\/g,''))
          }
        }
    };

    const waitOneSecond = (): Promise<void> => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 1000); // 1000 milliseconds = 1 second
        });
    }

    // useEffect(() => {
    //     if(context)
    //     axios.get(
    //         `${azure_function_url}/LLMModel?command=tree&context=${context}`, 
    //         {
    //             headers: { 'Authorization': bearerToken }
    //         })
    //         .then(res => {
    //             if (res.data !== null && res.data !== undefined && res.data?.length > 0)
    //                 setTagTree(res.data)
    //         })
    //         .catch(err => {console.log(err);message.error(err.toString())});

    // }, [context]);

    useEffect(() => {
        if (value && value != null && value !== lastValueRef.current && quillRef.current) {
            lastValueRef.current = value;   
            const m = Array.isArray(value) ? marked(value[0]) : marked(value);
            lastTextRef.current = m;
            console.log('set 1: ', m)
            setText(m);
            setFlow(undefined);            
        }
    }, [value]);

    useEffect(() => {
        if(text && text != null)
            if(context){
                axios.post(
                    `${azure_function_url}/LLMModel?command=context_idea_update_idx&context=${context}`,
                    {
                        id: id,
                        idx: idx,
                        dream: dream
                    },
                    {
                        headers: { 'Authorization': bearerToken }
                    }
                    )
                    .then(async res => {
                        // console.log(res)
                    })
            }
    }, [ idx ]);

    let timer : NodeJS.Timer = setInterval(()=>{}, 10000);

    useEffect(()=>{setLoading(loading)},[loading])

    useEffect(() => {
        
        const _id_flow = streamid;
        const pid = id;

        if(isLoading && !outputCard){
            clearInterval(timer);
            timer = setInterval(() => {
                axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id_flow}&pid=${pid}`,
                {
                    headers: { 'Authorization': bearerToken }
                })
                .then(async data => {
                    console.log(data.data)
                    if(data.data.finished && data.data.content !== ''){
                        if(timer)
                            clearInterval(timer);
                        await waitOneSecond();
                        const timer2 = setInterval(() => {
                            axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id_flow}`,
                            {
                                headers: { 'Authorization': bearerToken }
                            })
                            .then(data => {
                                if(data.data?.prompts){
                                    data.data.prompts.map((_p:any) => {
                                        if(_p.prompt === id){
                                            clearInterval(timer2);
                                            lastValueRef.current = _p.output;
                                            setContent(_p.output);
                                            const m = marked(_p.output);
                                            lastTextRef.current = m;
                                            console.log('set 2: ', m)
                                            setText(m);
                                            setFlow(undefined);  
                                            setLoading(false);
                                            scrollToBottom();
                                        }
                                    })
                                }
                            })
                            .catch(err=>{console.log(err)})
                        }
                        ,1000);
                    }
                    // else
                    {
                        axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id_flow}`,
                            {
                                headers: { 'Authorization': bearerToken }
                            })
                            .then(data=>{
                                if(data.data?.prompts){
                                    data.data.prompts.map((_p:any) => {
                                        if(_p.prompt === id){
                                            clearInterval(timer);
                                            lastValueRef.current = _p.output;
                                            const m = marked(_p.output);
                                            lastTextRef.current = m;
                                            console.log('set 3: ', m)
                                            setText(m);
                                            setContent(_p.output);
                                            setFlow(undefined);  
                                            setLoading(false);
                                            scrollToBottom();
                                        }
                                    })
                                }
                            });
                        const value = data.data.content + '...';
                        lastValueRef.current = value;
                        const m = marked(value);
                        lastTextRef.current = m;
                        console.log('set 4: ', m)
                        setText(m);
                        setFlow(undefined);  
                        scrollToBottom();
                    }
                });
            }, 1000);
        }
        else
            if(timer)
                clearInterval(timer);

    }, [ isLoading, loading ]);

    useEffect(() => {
        if(isLoading === true) {
            scrollToBottom();
        }
        else {
            if ((text && text !== lastTextRef.current || _title !== lastTitleRef.current || newCard === true) && isLoading !== true)
                
                if(context && dream && text){
                    if(value === text)
                        setText(value === text ? marked(text) : text);

                    setTextChange(true);
                    // axios.post(
                    //     `${azure_function_url}/LLMModel?command=context_idea_update&context=${context}`,
                    //     {
                    //         id: id,
                    //         dream: dream,
                    //         idx: idx,
                    //         title: _title,
                    //         content: value === text ? marked(text) : text,
                    //     },
                    //     {
                    //         headers: { 'Authorization': bearerToken }
                    //     })
                    //     .then(async res => {
                    //         // console.log(res)
                    //     })
                }
        }
    }, [text, _title]); // Dependency array: this effect runs when `text` changes
    
    const scrollToBottom = () => {
        if (quillRef.current) {
            // Get the editor element
            const editorElement = quillRef.current.getEditor().root;
            const scrollContainer = editorElement.closest('.ql-container')

            if (scrollContainer) {
                // Set the scrollTop to the height of the container to scroll to the bottom
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }      
        }
    };
    
    // Toggle between edit and display mode for the title
    const toggleEditTitle = () => {
        setIsEditingTitle(!isEditingTitle);
    };

    // Update title state and exit edit mode
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            toggleEditTitle(); // Exit edit mode on Enter key
        }
    };

    const handleTitleBlur = () => {
        toggleEditTitle(); // Exit edit mode when input loses focus
    };

    const quillClassName = noborder ? 'no-border' : card ? 'card' : '';

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const getAllTextAreaValues = () => {
        // Retrieve all form values
        const allValues = formRef.current?.getFieldsValue();
    
        // Extract the prompts array which contains the values of the TextAreas
        const promptsArray = allValues.prompts;
    
        // Check if promptsArray is valid and has items
        if (Array.isArray(promptsArray) && promptsArray.length > 0) {
            // Map through the array and extract the 'prompt' values
            const textAreaValues = promptsArray.map(item => item.prompt);
            return textAreaValues;
        } else {
            // Return an empty array if no valid data is found
            return [];
        }
    };

    const clearAllTextAreas = (text?: string) => {
        formRef.current?.setFieldsValue({ prompts: [{ prompt: text ?? ' ' }] });
    };

    const sendPrompt = async (data: any[]) => {
        const input = getAllTextAreaValues();
        
        clearAllTextAreas('working my magic...');
        const fid = uuidv4();
        setLoadingChat({ state: true });

        const turndownService = new TurndownService();

        // if(data){
        //     const fmData = new FormData();
        //     for(const element of data){
        //         fmData.append("file", element.blob, element.name);
        //     }

        //     await axios.post(
        //         `${azure_function_url}/LLMModel?command=files_upload&context=${context}&chunkSize=${8192}`,
        //         fmData,
        //         {
        //             maxContentLength: Number.POSITIVE_INFINITY,
        //             headers: {
        //                 "Content-Type": `multipart/form-data; boundery=${(fmData as any)._boundary}`,
        //                 'Authorization': bearerToken
        //             },
        //         }
        //     )

        //     message.success("files uploaded");
        // }

        const filteredYAML = ideaDynamic.replace(/\$_context_\$/g, context ?? user.id);
        const flow_json = 
            (yaml.load(filteredYAML) as any[])
            .map(x=>{
                if(x.prompt === "first"){
                    const allValues = formRef.current?.getFieldsValue();

                    const webSearch = allValues.websearch;
                    const useRag = allValues.agent === 'chat';
                    const selectedNodes = useRag && (!allValues.tags || allValues.tags?.length === 0) ? [user.id] : allValues.tags;

                    if(selectedNodes && selectedNodes?.length > 0){
                        x.rag = selectedNodes.map((module: any) => {
                            
                            const rag:any = yaml.load(moduleRAG.replace(/\$_name_\$/g, module));
                            const tags: any = selectedNodes[module];
                            if(tags) 
                                rag.tags = tags.map((path:any) => path.split('/').pop());
                                
                            return rag;
                        });
                    }

                    x.web = webSearch;

                    x.instructions = [ (webSearch || selectedNodes && selectedNodes?.length > 0 ? instructionStrictRAG : '') + '\n\n' + x.instructions
                        // .replace(/\$_instruction_rag_\$/g, selectedNodes && selectedNodes?.length > 0 ? instructionStrictRAG : '')
                        .replace(/\$_all_\$/g, text ? turndownService.turndown(text) : '')
                        .replace(/\$_input_\$/g, input) ];
                }

                return x;
            });

        axios.post(
            `${azure_function_url}/LLMModel?command=flow`, 
            {
                model: 'gpt-4',
                id: fid,
                name: 'name',
                description: 'description',
                json: flow_json,
                parameters: [],
                save: 'false',
                async: 'true',
                files: data
            },  
            {
                headers: { 'Authorization': bearerToken }
            })
            .then(res => {
                
                const _id = res.data;
                
                const timer = setInterval(() => {
                    axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=${"first"}`,
                    {
                        headers: { 'Authorization': bearerToken }
                    })
                    .then(async data => {
                        if(data.data.finished){
                            clearInterval(timer);

                            await waitOneSecond();
                            axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`,
                            {
                                headers: { 'Authorization': bearerToken }
                            })
                            .then(data => {
                                if(data.data?.prompts){
                                    setFlow(marked(Array.isArray(data.data.prompts[0].output) ? data.data.prompts[0].output.toString() : data.data.prompts[0].output));
                                    setLoadingChat({ state: false });
                                    clearAllTextAreas();
                                }
                            })
                            .catch(err=>{console.log(err)});
                        }
                        else if(data.data.content !== '') {
                            setFlow(marked(data.data.content + '...'));
                        }
                    });
                    
                }, 1000);
        }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});    
    };

    const copyHtmlUsingExecCommand = (htmlContent: string) => {
        const container = document.createElement('div');
        container.innerHTML = htmlContent;
        container.style.position = 'fixed'; // Avoids scrolling to bottom
        document.body.appendChild(container);
        
        const ww = window.getSelection();
        ww?.removeAllRanges();
        const range = document.createRange();
        range.selectNode(container);
        ww?.addRange(range);
      
        try {
          document.execCommand('copy');
          console.log('Content copied');
        } catch (err) {
          console.error('Failed to copy', err);
        }
      
        document.body.removeChild(container);
    }

    return (
    <div>
        <style>
            {`
            .card .ql-toolbar {
                border-radius: 7px !important;
                display: block !important;
            }

            .card .ql-container {
                border: 0 !important;
            }

            .ql-toolbar {
                display: none;
            }
            .ql-container {
                border: 1px solid #ccc !important; /* Adjust the color as needed */
                border-radius: 7px !important; /* Rounded corners only at the bottom */
            }

            .no-border .ql-toolbar {
                display: none;
            }

            .no-border .ql-container {
                border: 0 !important;
            }

            .no-border .ql-editor {
                padding: 0px !important;
            }
            `}
        </style>

        { !openModal ?
        
            card ?

            <Card
                
                title={
                    isEditingTitle ? (
                    <Input
                        value={_title}
                        onChange={handleTitleChange}
                        onKeyDown={handleTitleKeyDown}
                        onBlur={handleTitleBlur}
                        autoFocus
                    />
                    ) : (
                    <div onClick={toggleEditTitle}>{_title}</div>
                    )
                }
                size='small'
                actions={[
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}> {/* Wrap in flex container */}
                    <Checkbox
                        style={{ marginLeft: 20 }}
                        checked={isChecked}
                        disabled={disabled}
                        onChange={()=>{setChecked(!isChecked); setIsChecked(!isChecked); }}
                    >
                        {/* {label} */}
                    </Checkbox>
                    </div>
                ]}
                extra={ isLoading ? [
                    <Button 
                        key={0}
                        type="primary" 
                        icon={<LoadingOutlined />} 
                        style={{ 
                            color: 'rgba(0, 0, 0, 0.75)',
                            backgroundColor: 'transparent', 
                            borderColor: 'transparent', 
                            borderRadius: '0 0 0 0',
                            alignSelf: 'flex-end',
                            marginBottom: '0px',
                            boxShadow:'none'
                        }}
                    />
                    ] : 
                    outputCard ? 
                    [
                        <Button 
                            key={1}
                            type="primary" 
                            icon={<CopyOutlined />} 
                            style={{ 
                                color: 'rgba(0, 0, 0, 0.75)',
                                backgroundColor: 'transparent', 
                                borderColor: 'transparent', 
                                borderRadius: '0 0 0 0',
                                alignSelf: 'flex-end',
                                marginBottom: '0px',
                                boxShadow:'none'
                            }}
    
                            onClick={() => {
                                if (quillRef.current) { // Check if the Quill editor is mounted
                                    const editor = quillRef.current.getEditor(); // Access the Quill editor instance
                                    const contents = editor.getContents(); // Get the editor content in Delta format
                                    const markdown = deltaToMarkdown.deltaToMarkdown(contents.ops); // Convert Delta to Markdown
                                    copyHtmlUsingExecCommand(marked(markdown));
                                }
                            }}
                        />,
                        <Popconfirm
                            key={2}
                            title="Delete Idea"
                            description="Would you like to delete the idea?"
                            onConfirm={(e) => {
                                setFlow(undefined);
                                handleDelete && handleDelete();
                            }}
                            onCancel={() => { }}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <Button 
                           
                                type="primary" 
                                icon={<DeleteOutlined />} 
                                style={{ 
                                    color: 'rgba(0, 0, 0, 0.75)',
                                    backgroundColor: 'transparent', 
                                    borderColor: 'transparent', 
                                    borderRadius: '0 0 0 0',
                                    alignSelf: 'flex-end',
                                    marginBottom: '0px',
                                    boxShadow:'none'
                                }}
        
                                onClick={() => {
                                    setFlow(undefined);
                                }}
                            />
                        </Popconfirm>, 
                        <Popconfirm
                            key={3}
                            title="Create Idea"
                            description="Would you like to create the idea?"
                            onConfirm={(e) => {
                                handleDelete && handleDelete();
                                handleNewCard();
                                setFlow(undefined);
                            }}
                            onCancel={() => { }}
                            okText="Create"
                            cancelText="Cancel"
                        >
                            <Button 
                                
                                type="primary" 
                                icon={<FormOutlined />} 
                                style={{ 
                                    color: 'rgba(0, 0, 0, 0.75)',
                                    backgroundColor: 'transparent', 
                                    borderColor: 'transparent', 
                                    borderRadius: '0 0 0 0',
                                    alignSelf: 'flex-end',
                                    marginBottom: '0px',
                                    boxShadow:'none'
                                }}
                            />
                        </Popconfirm>
                        ]
                    :
                    [
                        !(textChange === true) ? [] : [<Button 
                            key={4}
                            shape="circle"
                            type="primary" 
                            icon={<SaveOutlined />} 
                            style={{ 
                                color: 'rgba(0, 0, 0, 0.75)',
                                backgroundColor: '#FFC107', // Warning yellow background color
                                borderColor: '#FFC107', // Warning yellow border color
                                borderRadius: '50%', // Makes the button round 
                                
                                alignSelf: 'flex-end',
                                marginBottom: '0px',
                                boxShadow:'none'
                            }}

                            onClick={() => {
                                // if (quillRef.current) { // Check if the Quill editor is mounted
                                //     const editor = quillRef.current.getEditor(); // Access the Quill editor instance
                                //     const contents = editor.getContents(); // Get the editor content in Delta format
                                //     const markdown = deltaToMarkdown.deltaToMarkdown(contents.ops); // Convert Delta to Markdown
                                //     copyHtmlUsingExecCommand(marked(markdown));
                                // }
                                if(context && dream && text){
                                    // console.log(text, value)
                                    // if(value === text)
                                    //     setText(value === text ? marked(text) : text)
                                    axios.post(
                                        `${azure_function_url}/LLMModel?command=context_idea_update&context=${context}`,
                                        {
                                            id: id,
                                            dream: dream,
                                            idx: idx,
                                            title: _title,
                                            content: value === text ? marked(text) : text,
                                        },
                                        {
                                            headers: { 'Authorization': bearerToken }
                                        })
                                        .then(async res => {
                                            setTextChange(false);
                                            console.log(res)
                                            message.success('Idea Saved');
                                            forceUpdate();
                                        })
                                }
                            }}
                        />],
                    <Button 
                        key={5}
                        
                        type="primary" 
                        icon={<CopyOutlined />} 
                        style={{ 
                            color: 'rgba(0, 0, 0, 0.75)',
                            backgroundColor: 'transparent', 
                            borderColor: 'transparent', 
                            borderRadius: '0 0 0 0',
                            alignSelf: 'flex-end',
                            marginBottom: '0px',
                            boxShadow:'none'
                        }}

                        onClick={() => {
                            if (quillRef.current) { // Check if the Quill editor is mounted
                                const editor = quillRef.current.getEditor(); // Access the Quill editor instance
                                const contents = editor.getContents(); // Get the editor content in Delta format
                                const markdown = deltaToMarkdown.deltaToMarkdown(contents.ops); // Convert Delta to Markdown
                                copyHtmlUsingExecCommand(marked(markdown));
                            }
                        }}
                    />,
                    <Popconfirm
                        key={6}
                        title="Delete Idea"
                        description="Would you like to delete the idea?"
                        onConfirm={(e) => {
                            // e.stopPropagation(); // Prevent the menu from triggering its default behavior
                            axios.get(
                                `${azure_function_url}/LLMModel?command=context_idea_delete&context=${context}&id=${id}&dream=${dream}`,
                                {
                                    headers: {
                                        'Authorization': bearerToken
                                    }
                                }
                            ).then(async res => {
                                console.log(res)
                                handleDelete && handleDelete();
                            })
                        }}
                        onCancel={() => { }}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button
                            key={uuidv4()} 
                            type="text"
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>,
                    <Button 
                        key={7}
                        type="primary" 
                        icon={<FullscreenOutlined />} 
                        style={{ 
                            color: 'rgba(0, 0, 0, 0.75)',
                            backgroundColor: 'transparent', 
                            borderColor: 'transparent', 
                            borderRadius: '0 0 0 0',
                            alignSelf: 'flex-end',
                            marginBottom: '0px',
                            boxShadow:'none'
                        }}

                        onClick={() => {
                            if (quillRef.current) { // Check if the Quill editor is mounted
                                setOpenModal(true);
                                setTimeout(forceUpdate, 250);
                            }
                        }}
                    />].flat()
                }
            >
                <div className={quillClassName}>
                    <ReactQuill readOnly={disabled} placeholder={placeholder} ref={quillRef} onChange={handleChange} value={text} />
                    <div ref={messagesEndRef}></div>
                </div>
            </Card>
            :
            <div className={quillClassName}>
                <ReactQuill readOnly={disabled} placeholder={placeholder} ref={quillRef} value={text} onChange={handleChange} modules={{toolbar:[]}} /> 
            </div>
        :

        <Modal okType={'default'} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }} title="Idea" width={1500} onCancel={() => setOpenModal(false)} onOk={() => { setOpenModal(false); setIsEditingTitle(false) }}  closable={true} open={openModal}>
            <div style={{height:'100%'}}>
                <div style={{height:'calc(100% - 50px)'}}>
                    <Row>
                        <Col style={{paddingRight: flow ? '10px' : undefined }} span={flow ? 12 : 24}>
                            <Card
                                // key={uuidv4()}
                                title={
                                    isEditingTitle ? 
                                    <Input
                                        value={_title}
                                        onChange={handleTitleChange}
                                        onKeyDown={handleTitleKeyDown}
                                        onBlur={handleTitleBlur}
                                        autoFocus
                                    />
                                    :
                                    <div onClick={toggleEditTitle}>{_title}</div>
                                }
                                size='small'
                                extra={[
                                    <Button 
                                        key={0}
                                        type="primary" 
                                        icon={<CopyOutlined />} 
                                        style={{ 
                                            color: 'rgba(0, 0, 0, 0.75)',
                                            backgroundColor: 'transparent', 
                                            borderColor: 'transparent', 
                                            borderRadius: '0 0 0 0',
                                            alignSelf: 'flex-end',
                                            marginBottom: '0px',
                                            boxShadow:'none'
                                        }}

                                        onClick={() => {
                                            if (quillRef.current) { // Check if the Quill editor is mounted
                                                const editor = quillRef.current.getEditor(); // Access the Quill editor instance
                                                const contents = editor.getContents(); // Get the editor content in Delta format
                                                const markdown = deltaToMarkdown.deltaToMarkdown(contents.ops); // Convert Delta to Markdown
                                                copyHtmlUsingExecCommand(marked(markdown));
                                            }
                                        }}
                                    />, 
                                    <Button 
                                        key={2}
                                        type="primary" 
                                        icon={<DeleteOutlined />} 
                                        style={{ 
                                            color: 'rgba(0, 0, 0, 0.75)',
                                            backgroundColor: 'transparent', 
                                            borderColor: 'transparent', 
                                            borderRadius: '0 0 0 0',
                                            alignSelf: 'flex-end',
                                            marginBottom: '0px',
                                            boxShadow:'none'
                                        }}

                                        onClick={() => {
                                            handleDelete && handleDelete();
                                        }}
                                    />]
                                }
                            >
                                <div className={quillClassName}>
                                    {/* <ReactQuill readOnly={disabled} placeholder={placeholder} ref={quillRef} onChange={handleChange} value={text} /> */}
                                    <ReactQuill readOnly={disabled} placeholder={placeholder} ref={quillRef} onChange={handleChange} value={text} />
                                </div>
                            </Card>
                        </Col>

                        { flow && (<Col style={{paddingLeft:'10px'}} span={12}>
                            <Card
                                title={'SIKE Output'}
                                size='small'
                                extra={flow ? [
                                    <Button 
                                        key={0}
                                        type="primary" 
                                        icon={<SyncOutlined />} 
                                        style={{ 
                                            color: 'rgba(0, 0, 0, 0.75)',
                                            backgroundColor: 'transparent', 
                                            borderColor: 'transparent', 
                                            borderRadius: '0 0 0 0',
                                            alignSelf: 'flex-end',
                                            marginBottom: '0px',
                                            boxShadow:'none'
                                        }}

                                        onClick={() => {
                                            setText(flow);
                                            setFlow(undefined);
                                        }}
                                    />,
                                    <Button 
                                        key={1}
                                        type="primary" 
                                        icon={<CopyOutlined />} 
                                        style={{ 
                                            color: 'rgba(0, 0, 0, 0.75)',
                                            backgroundColor: 'transparent', 
                                            borderColor: 'transparent', 
                                            borderRadius: '0 0 0 0',
                                            alignSelf: 'flex-end',
                                            marginBottom: '0px',
                                            boxShadow:'none'
                                        }}

                                        onClick={() => {
                                            if (quillRef.current) { // Check if the Quill editor is mounted
                                                const editor = quillRef.current.getEditor(); // Access the Quill editor instance
                                                const contents = editor.getContents(); // Get the editor content in Delta format
                                                const markdown = deltaToMarkdown.deltaToMarkdown(contents.ops); // Convert Delta to Markdown
                                                copyHtmlUsingExecCommand(marked(markdown));
                                            }
                                        }}
                                    />
                                ] : []
                                }
                            >
                                

                                <div className={quillClassName}>
                                    <ReactQuill readOnly={disabled} placeholder={'magic in the making...'} ref={quillRef} value={flow} />
                                </div>
                            </Card>
                        </Col>) }
                    </Row>
                </div>
                
                <SInput 
                    handleMessageSend={sendPrompt}
                    isLoadingChat={isLoadingChat.state}
                    isMobile={isMobile}
                    formRef={formRef}
                    messageInputRef={messageInputRef}
                    bearerToken={bearerToken}
                    context={context}
                    
                    ref_input={ref_input}
                    ref_agent={ref_agent}
                    ref_tags={ref_tags}
                    ref_send={ref_send}

                    bottom={false}
                    tree={true}

                    agent={'GPT'}
                />
            </div>
        </Modal>
        }
    </div>);
}