// App.tsx

import React, { useRef, useState, useEffect, useReducer } from 'react';

import { Row, Col, Collapse, Flex, Segmented, Avatar, List, Typography, Upload, DatePicker, Table, Menu, Popconfirm, Card, FloatButton, Input, Button, Tabs, message } from 'antd';
import type { UploadProps, MenuProps } from 'antd';

import { EyeOutlined, ShopOutlined, SearchOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, KeyOutlined, UploadOutlined, CloudDownloadOutlined, DatabaseOutlined, CloseOutlined, CheckOutlined, EllipsisOutlined, PlusCircleOutlined, TeamOutlined, MessageOutlined, DeleteOutlined} from '@ant-design/icons';

import { Employees } from '../employees/Employees';

import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';


import axios from 'axios';

import { SIKE } from '../sike/SIKE';
import { PromptFlow } from '../sike/PromptFlow';
import { Idea } from '../sike/Idea';

import mi from '../../yml/marketintelligence/mi.yaml';
import ForYAML from '../../yml/marketintelligence/for.yaml';
import ForMiYAML from '../../yml/marketintelligence/for_market.yaml';
import SelfOptimise from '../../yml/marketintelligence/self_optimise.yaml';
import SelfOptimisePrompt from '../../yml/marketintelligence/self_optimise_prompt.yaml';


import ifmObj from '../../json/ifm.json';
import neuObj from '../../json/neu_capital.json';
import partnerObj from '../../json/partners_growth.json';
import p1Obj from '../../json/p1.json';

import dayjs from 'dayjs';

import hljs from 'highlight.js';
import { marked } from 'marked';
import 'highlight.js/styles/github.css';  // Or any other style you prefer


import neu_crm_deals from '../../json/neu_crm/Deals.json';
import neu_crm_notes from '../../json/neu_crm/ClientNotes.json';


const { Title, Paragraph, Text, Link } = Typography;
const { Dragger } = Upload;
const { Column } = Table;

const toHashTable = (prompts:any) : any => {
    if(Array.isArray(prompts))
        return prompts.map((prompt: any) => {
            if(prompt.each){
                return { key: prompt.prompt, value: prompt.each.map((x:any)=> {
                    return { key: x.prompt, value: x.output ? toHashTable(x.output) : [] };
                }) };
            }
            if(typeof prompt.subprompts !== "string" && Array.isArray(prompt.subprompts)){
                return toHashTable(prompt.subprompts)
            }
            return { key: prompt.parameters ? prompt.parameters[0].value : prompt.prompt, value: prompt.subprompts.output ?? prompt.subprompts };
        })
    else{
        return { key: prompts.parameters ? prompts.parameters[0].value : prompts.prompt, value: toHashTable(prompts.output) };
    }
}

type MenuItem = Required<MenuProps>['items'][number];

const InputLabel: React.FC<any>  = (parent: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(parent.label);
    const [lastValue, setLastValue] = useState(parent.label);

    useEffect(()=>{
        setEditValue(parent.label)
        setLastValue(parent.label)
    },[parent.label])


    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    return (
        parent.keyVal === '_search_' ?
            <div 
                style={{ 
                    display: 'flex', 
                    width: '100%', 
                    
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    
                    borderRadius: '15px 15px', 
                    border: '1px solid rgba(0, 0, 0, 0.25)', 
                    margin: '10px 10px 20px 0px' 

                }}>
                <Input
                    style={{
                        marginLeft:'7px', 
                        border: '0px',
                    }}
                    autoFocus
                    onChange={(e) => {
                        e.stopPropagation();
                        parent.setSearch(e.target.value);
                    }}
                />
                <Button
                    type="text"
                    icon={<SearchOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                        setLastValue(editValue);

                        console.log({
                            id: parent.keyVal,
                            name: editValue
                        })

                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                            {
                                id: parent.keyVal,
                                name: editValue
                            },
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            }
                        ).then(res => {
                            console.log(res);
                        }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
            </div>
        :
        isEditing ?
            <div style={{ display: 'flex', width: '100%', padding: '0px !important' }}>
                <Input
                    style={{marginLeft:'7px' }}
                    autoFocus
                    defaultValue={editValue}
                    onChange={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                    }}
                    onPressEnter={(e) => {
                            e.stopPropagation();
                            axios.post(
                                `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                                {
                                    name: editValue
                                },
                                {
                                    headers: { 'Authorization': parent.bearerToken }
                                }
                            ).then(res => {
                                setIsEditing(false);
                            });
                        
                    }}
                    onBlur={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                            {
                                name: e.target.value
                            },
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            }
                        ).then(res => {
                            setIsEditing(false);
                        });
                    }}
                />
                <Button
                    type="text"
                    icon={<CheckOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                        setLastValue(editValue);

                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                            {
                                name: editValue
                            },
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            }
                        ).then(res => {
                            console.log(res);
                        }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditValue(lastValue);
                        setIsEditing(false);
                    }}
                />
            </div>
        :
            <div 
                style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    width:'100%',
                    borderRadius: '7px',
                    height: '70px',
                    paddingLeft: parent.selectedThread === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '9px' : '12px', // adjust padding if needed when selected
                    borderLeft: parent.selectedThread === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '3px solid #d9d9d9' : undefined, // add a left border to indicate selection
                    backgroundColor: parent.selectedThread === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '#f5f5f5' : undefined, // light blue background for selection, you can choose any color you prefer
                }}
            >
                {parent.isAddThread ? <></>:parent.menuType === 'threads'?<ShopOutlined />:<UserOutlined/>}
                <a 
                    style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: parent.keyVal === 'add_thread' || parent.keyVal === 'add_team' ? '' : '10px' }}
                    onClick={(e)=>{

                        if(parent.keyVal === 'add_thread' || parent.keyVal === 'add_team')
                            parent.handleAddClick(e);
                        else if(parent.menuType === 'threads'){
                            parent.setThread(parent.keyVal);
                            parent.setLabel(parent.label);
                        }
                        else{
                            parent.setPrompt(parent.keyVal);
                        }
                        
                    }}
                >
                    {editValue}
                </a>
                {parent.isAddThread ? 
                    <Button
                        type="text"
                        icon={<PlusCircleOutlined style={{ flexShrink: 1 }}/>}
                        onClick={parent.handleAddClick}
                    /> 
                : parent.menuType === 'threads' ?
                    <>
                        <Button
                            type="text"
                            icon={<EllipsisOutlined style={{ flexShrink: 1 }} />}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent the menu from triggering its default behavior
                                setIsEditing(true);
                            }}
                        />
                        { parent.selectedThread === parent.keyVal ? 
                        <Popconfirm
                            title="Delete Thread"
                            description="Would you like to delete the thread?"
                            onConfirm={(e) => {
                                setIsEditing(false);

                                axios.post(
                                    `${azure_function_url}/LLMModel?command=context_thread_delete&context=${parent.context}&thread=${parent.keyVal}`,
                                    {
                                        name: editValue
                                    },
                                    {
                                        headers: { 'Authorization': parent.bearerToken }
                                    }
                                ).then(res => {
                                    setIsEditing(false);
                                    parent.reload();
                                }).catch(err => {console.log(err);message.error(err.toString())});
                            }}
                            onCancel={() => { }}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            
                        />
                        </Popconfirm>
                        :<></>
                        }
                    </>
                :<></>
                }
            </div>
    );
};

const insertItemInTree = (root: any[], parts: string[], item: any, pathSoFar = '') => {
    let currentLevel = root;
    parts.forEach((part, index) => {
        // Update the path so far with the current part
        const currentPath = pathSoFar + (index > 0 ? '/' : '') + part;

        if (index < parts.length - 1) {
            // We are dealing with a directory
            let directory = currentLevel.find(child => child.name === part);
            if (!directory) {
                directory = { name: part, children: [], key: currentPath };
                currentLevel.push(directory);
            }
            currentLevel = directory.children!;
        } else {
            // We are dealing with a file (leaf)
            currentLevel.push({ ...item, name: part, key: currentPath });
        }
    });
};

const transformFileList = (fileList: any[]): any[] => {
    const root: any[] = [];

    fileList.forEach((item) => {
        const parts = item.name.split('/');
        insertItemInTree(root, parts, item);
    });

    return root;
};

const renderMarkdown = (_markdownContent: any) => {

    let markdownContent = '';
    if(Array.isArray(_markdownContent))
        markdownContent = _markdownContent.join('\n');
    else
        markdownContent = _markdownContent;
    
    if(markdownContent.startsWith('```markdown'))
        markdownContent = markdownContent.substring('```markdown'.length);

    if(markdownContent.endsWith('```'))
        markdownContent = markdownContent.substring(0, markdownContent.length - '```'.length);
    
    // Step 1: Convert markdown to HTML
    const htmlContent = marked(markdownContent.toString());

    // Step 2: Parse and highlight code blocks
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find all <pre> elements containing <code> (i.e., code blocks)
    const codeBlockElements = doc.querySelectorAll('pre > code');

    codeBlockElements.forEach(codeElem => {
        const rawCode = codeElem.textContent || '';
        const highlighted = hljs.highlightAuto(rawCode).value;
        codeElem.innerHTML = highlighted;

        // Wrap the code block inside a div with a 'code-card' class
        const wrapper = doc.createElement('div');
        wrapper.className = 'code-card';
        const parentPre = codeElem.parentElement;
        if (parentPre) {
            parentPre.parentNode?.replaceChild(wrapper, parentPre);
            wrapper.appendChild(parentPre);
        }
    });

    // Step 3: Return the updated HTML
    return doc.body.innerHTML;
};

interface CardItem {
    id: string;
    title: string;
    // prompt?: string;
    content: string;
    image: string;
    source_icon: string;
    data: any;
    show: boolean;
    // loading?: number;
    // newCard?: boolean;
    // checked?: boolean;
    // streamid?: string;
}


export const MarketIntelligence: React.FC<any> = (parent) => {

    const [thread, setThread] = useState('-');
    const [label, setLabel] = useState('');
    const [search, setSearch] = useState('');
    const [activeTabKey, setActiveTabKey] = useState('chat'); // Default active tab key
    const [menuType, setMenuType] = useState('threads'); // Default active tab key
    const [prompt, setPrompt] = useState<string>(); // Default active tab key
    const [flows, setFlows] = useState<Record<string,any>>(); // Default active tab key
    const [openKeys, setOpenKeys] = useState(['1']);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const [isLoadingFiles, setLoadingFiles] = useState({ state: true});
    const [fileList, setFileList] = useState<any[]>();
    const [documentType, setDocumentType] = useState<string>("8191");

    const [context, setContext] = useState<string>(parent.context);
    const [sikeUpdateCounter, setSikeUpdateCounter] = useState(0); // Default active tab key
    const [openMenu, setOpenMenu] = useState(!parent.isMobile);

    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [cards, setCards] = useState<CardItem[]>([]);
    const [visibleCard, setVisibleCard] = useState<CardItem>();

    const [crmObject, setCRMObject] = useState<any>();
    const [marketDataHash, setMarketDataHash] = useState(toHashTable(ifmObj));
    const [marketDataName, setMarketDataName] = useState("IFM Investors");

    // const [processingMessage, setProcessingMessage] = useState<string>();
    const [processingStructure, setProcessingStructure] = useState<any>();
    const processingEndRef = useRef<HTMLDivElement | null>(null);



    const structuredCRM = neu_crm_deals.data.map(deal => { return {
        id: deal.id,
        Account_Name: deal.Account_Name.name,
        Amount: deal.Amount,
        Business_Type: deal.Business_Type,
        Company_Background: deal.Company_Background,
        Conclusion: deal.Conclusion,
        Contact_Name: deal.Contact_Name?.name,
        Created_By_Name: deal.Created_By.name,
        Created_By_Email: deal.Created_By.email,
        Current_Funding_Structure: deal.Current_Funding_Structure,
        Deal_Name: deal.Deal_Name,
        Description: deal.Description,
        Engagement: deal.Engagement,
        Stage: deal.Stage,
        Status_Comment: deal.Status_Comment,
        Previous_Stage: deal.Previous_Stage,
        Lead_Source: deal.Lead_Source,
        Margin_Target: deal.Margin_Target,
        Investor_Target: deal.Investor_Target,
        notes: neu_crm_notes.data.filter(note => note.Parent_Id.id === deal.id).map(note => { return { Note_Title: note.Note_Title, Note_Content: note.Note_Content, Modified_Time: note.Modified_Time } } )
    }})

    useEffect(() => {

        

        console.log(neu_crm_notes.data)

        console.log(neu_crm_deals.data)
        console.log(structuredCRM);

        // const x = structuredCRM.filter(x=>x.id==='2873638000088677014')[0]
        // setCRMObject(x)
    },[]);

    const loadThreadList = (set_thread = true) => {

        const it = structuredCRM
            .map(x=>{return { name: x.Account_Name + (x.notes.length > 0 ?"+":""), thread: x.id }})
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((x:any) => getItem(x.thread === '_flows_' ? 'Workflows' : x.name, x.thread));

        // const it = [
        //     {name: "IFM Investors", thread:"ifm_investors"},
        //     {name: "NEU Capital", thread:"neu_capital"},
        //     {name: "Partners Growth", thread:"partners_growth"},
        //     {name: "Platform 1 CX", thread:"p1"}
        // ].map((x:any) => getItem(x.thread === '_flows_' ? 'Workflows' : x.name, x.thread));


        it.unshift(getItem('', '_search_', (e: React.Key) => handleAddOnTop(e, it.length)));
        it.push(getItem('New Company', 'add_thread', (e: React.Key) => handleAddOnTop(e, it.length)));
        setMenuItems(it);
        forceUpdate();


        // axios.get(
        //     `${azure_function_url}/LLMModel?command=context_thread_list&context=${context}`, 
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(res => {
        //         const it = res.data.map((x:any) => getItem(x.thread === '_flows_' ? 'Workflows' : x.name, x.thread));
        //         it.unshift(getItem('', '_search_', (e: React.Key) => handleAddOnTop(e, it.length)));
        //         it.push(getItem('New Thread', 'add_thread', (e: React.Key) => handleAddOnTop(e, it.length)));
        //         setMenuItems(it);
        //         forceUpdate();
                
        //         if(set_thread && res.data.length > 0){
        //             setThread(res.data[0].thread);
        //             setLabel(res.data[0].name);
        //         }
        //     })
        //     .catch(err => {console.log(err);message.error(err.toString())});
    }

    const loadFlowList = (set_thread = true) => {
        axios.get(
            `${azure_function_url}/LLMModel?command=flow_list&context=${context}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                const records = res.data.reduce((acc: Record<string, any>, curr: any) => {
                    const key = String(curr.id); // Ensuring the key is a string
                    acc[key] = curr;
                    return acc;
                }, {});
                setFlows(records);
                const it = res.data.map((x:any) => getItem(x.name, x.id));
                it.push(getItem('New Team', 'add_team', (e: React.Key) => handleAddOnTop(e, it.length)));
                setMenuItems(it);
                if(set_thread && res.data.length > 0 || prompt === undefined)
                   setPrompt(res.data[0]?.data?.id);                
            })
            .catch(err => {console.log(err);message.error(err.toString())});
    }

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        addOnTop?: (key: React.Key) => void,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group'
    ): MenuItem => {   
        // Function to call when the Add Thread button is clicked
        const handleAddClick = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation(); // Prevent triggering click on menu item
            addOnTop && addOnTop(key);
        };
      
        // If the key is 'add_thread', we want to add an "Add" button instead of an "Edit" button
        const isAddThread = key === 'add_thread' || key === 'add_team';
        
        return {
            key,
            label: <InputLabel search={search} setSearch={setSearch} bearerToken={parent.bearerToken} menuType={menuType} context={context} label={label} keyVal={key} selectedThread={thread} selectedPrompt={prompt} isAddThread={isAddThread} handleAddClick={handleAddClick} setThread={setThread} setLabel={setLabel} setPrompt={setPrompt} reload={loadThreadList}/>,
            type,
        } as MenuItem;
    }
    
    const handleAddOnTop = (key: React.Key,num : number) => {
        if (key === 'add_thread') {
            const name = `Thread ${num}`;
            const id = `${Date.now()}`
            const newItem = getItem(name, id);

            axios.post(
                `${azure_function_url}/LLMModel?command=context_thread_update&context=${context}&thread=${id}`,
                {
                    name: name
                },
                {
                    headers: { 'Authorization': parent.bearerToken }
                }
                )
                .then(res => {
                    setMenuItems((prevItems) => {
                        // Find the index of 'Add Thread'
                        const addThreadIndex = prevItems.findIndex(item => item?.key === 'add_thread');
                        // Insert new item just above 'Add Thread'
                        const newItems = [...prevItems];
                        newItems.splice(addThreadIndex, 0, newItem);
                        return newItems;
                    });

                    setThread(id);
                    setLabel(name);
                }).catch(err => {console.log(err);message.error(err.toString())});       
        }
        else{
            setPrompt('add_');
        }
    };

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    useEffect(() => {
        if(menuType === 'threads'){
            loadThreadList(thread === '-');

            

            const crmObj = structuredCRM.filter(x=>x.id===thread)[0];
            const name = crmObj?.Account_Name;
            if(name){
                console.log('loading: ', name)
                axios.post(
                    `${azure_function_url}/LLMModel?command=flow`, 
                    {
                        model: 'gpt-4-mini',
                        yaml: ForMiYAML,
                        save: 'false',
                        async: 'true',
                        parameters: [{
                            id: "company",
                            value: name
                        }]
                    },  
                    {
                        headers: { 'Authorization': parent.bearerToken }
                    })
                    .then(res => {

                        const _id = res.data;
                        
                        const timer = setInterval(() => {
                            axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=__all__`,
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            })
                            .then((data:any) => {

                                console.log(data.data);
                            
                                if(data.data.finished){
                                    clearInterval(timer);

                                    axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`,
                                        {
                                            headers: { 'Authorization': parent.bearerToken }
                                        })
                                        .then((data:any) => {
                                            console.log(data.data.prompts)
                                            console.log(toHashTable(data.data.prompts));
                                            setMarketDataName(name);
                                            setMarketDataHash(toHashTable(data.data.prompts));
                                            // setProcessingMessage(undefined);
                                            setProcessingStructure(undefined);

                                            setCRMObject(crmObj)
                                        });

                                    // waitOneSecond()
                                    // .then(() => {
                                    //     updateHistory();
                                    //     clearAllTextAreas();
                                    // });
                                }
                                else 
                                // if(data.data.content.trim() !== '')
                                {
                                    setProcessingStructure(data.data);
                                    // setProcessingMessage(data.data.content + '...');
                                    processingEndRef.current?.scrollIntoView({ behavior: "smooth" });
                                    // scrollToBottom();
                                }
                            });
                        }, 1000);

                        console.log("3", res.data.prompts)
                    });
            }

            if(thread==="ifm_investors"){
                setMarketDataName("IFM Investors");
                setMarketDataHash(toHashTable(ifmObj));
            }
            else if(thread==="neu_capital"){
                setMarketDataName("Neu Capital");
                setMarketDataHash(toHashTable(neuObj));
            }
            else if(thread==="p1"){
                setMarketDataName("Platform 1 CX");
                setMarketDataHash(toHashTable(p1Obj));
            }
            
            else if(thread==="partners_growth"){
                setMarketDataName("Partners Growth");
                setMarketDataHash(toHashTable(partnerObj));
            }

        }
        else
            loadFlowList(false);

    },[context, thread, prompt, menuType]);

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        accept: '.pdf, .ts, .md, .docx',
        defaultFileList: fileList,
        customRequest: async (options: any) => { 
            
            // eslint-disable-next-line
            const { onSuccess, onError, file, onProgress } = options;
            const buffer = await (file as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);

            const fmData = new FormData();

            setLoadingFiles({ state: true });
    
            fmData.append("file", new Blob([bytes], {type:file.type}), file.name);

            const res = await axios.post(
                `${azure_function_url}/LLMModel?command=files_upload&context=${context}&chunkSize=${documentType}`,
                fmData,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        "Content-Type": `multipart/form-data; boundery=${(fmData as any)._boundary}`,
                        'Authorization': parent.bearerToken
                    },
                }
            )

            const files = res.data.map((row: any, i: number) => {
                return {
                    uid: i,
                    id: row.id,
                    name: row.name,
                    status: 'done',
                    data: row.data,
                    added: row.added,
                    last_read: row.last_read,
                    initialChunkSize: row.initialchunksize
                };
            }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);

            setLoadingFiles({ state: false });
            setFileList(files);
            setSikeUpdateCounter(x=>x=x+1);

            if(onSuccess){
                onSuccess("Ok");
            }            
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            }
            else if (status === 'removed') {
                message.success(`${info.file.name} file removed successfully.`);
            }
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };
    

    useEffect(()=>{
        axios.get(
            `${azure_function_url}/LLMModel?command=news_get`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(async res => { 
                console.log(res.data)
                setCards(res.data.map((x: any)=>{ 
                    return {
                        id: x.data.article_id,
                        title: x.data.title,
                        content: x.data.content,
                        image: x.data.image_url,
                        source_icon: x.data.source_icon,
                        show: false
                    }
                }));
                // setCards(res.data);
            });
    }, []);

    // const marketDataHash = toHashTable(marketData);

    useEffect(() => {
        setLoadingFiles({ state: true });

        axios.get(
            `${azure_function_url}/LLMModel?command=files_list&context=${context}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                if(res.data){
                    const files = res.data.map((row: any, i: number) => {
                        return {
                            uid: i,
                            id: row.id,
                            name: row.name,
                            status: 'done',
                            data: row.data,
                            added: row.added,
                            last_read: row.last_read,
                            initialChunkSize: row.initialChunkSize
                        };
                    }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);
                    setLoadingFiles({ state: false });
                    setFileList(files);
                    if(parent.isMobile)
                        setOpenMenu(false);
                }
                else
                    setLoadingFiles({ state: false });
        })
        .catch(err => {console.log(err);message.error(err.toString())});

    }, [context, thread]);

    useEffect(() => {
        setThread('-');
        setLabel('');
        setSearch('');
    
        setContext(parent.context);   

        // console.log("2", toHashTable(ifmObj))

    }, [parent.context]);
       
    return (
        <>
            <div style={{
                position: 'relative', 
                display: 'flex', 
                justifyContent: 'center', 
                height: 'calc(100vh - 65px)'
            }}>
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={(keys) => {
                        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                        if (latestOpenKey){
                            setOpenKeys(keys);
                        } else {
                            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
                        }
                    }}
                    style={{ 
                        display: openMenu ? undefined: 'none',
                        width: parent.isMobile ? '100%' : 400, 
                        fontSize: '25px',
                        fontWeight: 100,
                        
                        position: 'sticky',
                        top: parent.isMobile ? undefined : '100px', // Adjust the value as needed to position the menu correctly
                        overflowY: 'auto' 
                    }}
                    items={menuItems.filter((x:any)=> x.key === '_search_' || x.key === 'add_dream' || x.label.props.label.toLowerCase().indexOf(search?.toLowerCase()) > -1)}
                />
                <div 
                    className='custom-card' 
                    style={{
                        display: openMenu && parent.isMobile ? 'none' :  undefined,
                        width:  '100%', 
                        height: '100%',
                        marginRight: parent.isMobile ? undefined :  '35px', 
                        marginLeft: parent.isMobile || openMenu ? undefined:  '55px',
                    }}
                >
                    <Card 
                        bordered={false} 
                        className='custom-card'
                        style={{
                            // width: '100%',
                            // height: '100%',
                        }}
                    >
                        <Row 
                            gutter={20} 
                            style={{
                                // width:'calc(100%)', 
                                // paddingRight: '25px', 
                                height: 'calc(100vh - 90px)'
                                
                            }}
                        >
                            <Col 
                                style={{
                                    // overflowY: 'auto', 
                                    // height: 'calc(100vh - 100px)'
                                                                            
                                }} 
                                span={14}
                            >
                                <Card 
                                    bordered={false} 
                                    // loading={messages?.length === 0}=
                                    style={{
                                        // width: '100%',
                                        // marginRight:'30px',
                                        // height: '100%'
                                        
                                    }}
                                >  
                                    { processingStructure?.structuredContent &&
                                    <div
                                        style={{ 
                                            
                                            height: 'calc(100vh - 110px)',
                                            overflowY: 'auto'
                                        }}
                                    >
                                        <Typography>
                                            <Title>Generating Report</Title>
                                            <Collapse
                                                size="small"
                                                items={
                                                    Object.keys(processingStructure.structuredContent).map((key:string)=>{ return { key: key.substring(key.indexOf('/') + 1), value: processingStructure.structuredContent[key] } })
                                                    .map((x:any) => { 
                                                        return {
                                                            key: x.key,
                                                            label: x.key,
                                                            children: 
                                                                <div 
                                                                style={{ 
                                                                    paddingLeft: '40px', 
                                                                    paddingRight: '40px', 
                                                                    fontSize: '18px' ,
                                                                    wordWrap: 'break-word'
                                                                }} 
                                                                dangerouslySetInnerHTML={{ __html: renderMarkdown(x.value) }} 
                                                            />
                                                        }
                                                    })
                                                }
                                            />
                                            
                                            <div ref={processingEndRef} />
                                        </Typography>
                                    </div>
                                    }
                                    { !(processingStructure?.structuredContent) &&
                                    <Typography>
                                        <Title>{marketDataName}</Title>

                                        <div 
                                            style={{
                                                overflowY: 'auto',
                                                height: 'calc(100vh - 200px)',
                                                paddingRight: '15px'
                                            }}
                                        >
                                            <Collapse
                                                size="small"
                                                items={
                                                    [{
                                                        key: 1,
                                                        label: (marketDataHash.find((x:any)=>x.key === "descriptions").value).substring(0,500)+'...',
                                                        children: 
                                                            <div 
                                                                style={{ 
                                                                    paddingLeft: '40px', 
                                                                    paddingRight: '40px', 
                                                                    fontSize: '18px' ,
                                                                    wordWrap: 'break-word'
                                                                }} 
                                                                dangerouslySetInnerHTML={{ __html: renderMarkdown(marketDataHash.find((x:any)=>x.key === "descriptions").value) }} 
                                                            />
                                                    
                                                    }]
                                                }
                                            />   

                                            <Title level={3}>News</Title>
                                            <Row gutter={16}>
                                                {cards.slice(0,3).map(card=>
                                                <Col span={8}>
                                                    <Card 
                                                        bordered={false}
                                                        onClick={()=>{setVisibleCard(card)}}
                                                        cover={
                                                            <img
                                                                style={{ 
                                                                    height: 150, 
                                                                    width: '100%', 
                                                                    objectFit: 'cover' 
                                                                }}
                                                                alt="example"
                                                                src={card.image ?? card.source_icon}
                                                            />
                                                        }
                                                        // actions={[
                                                        //     <EyeOutlined onClick={()=>{setVisibleCard(card)}} key="ellipsis" />,
                                                        //   ]}
                                                    >
                                                        <Card.Meta
                                                            avatar={<Avatar src={card.source_icon} />}
                                                            title={card.title}
                                                            description={
                                                                <div
                                                                    
                                                                    style={{
                                                                        
                                                                        display: '-webkit-box',
                                                                        WebkitBoxOrient: 'vertical',
                                                                        overflow: 'hidden',
                                                                        WebkitLineClamp: 2, // Limit to 3 lines
                                                                        lineClamp: 2, // Fallback for other browsers
                                                                    }}
                                                                >
                                                                    
                                                                </div>
                                                            }
                                                        />

                                                        {card.show ? card.content : undefined}
                                                    </Card>
                                                </Col>
                                                )}
                                            </Row>

                                            { crmObject && 
                                            <><Title level={3}>CRM</Title>
                                            <JsonView collapsed={0} src={crmObject} />
                                            </>}

                                            { crmObject && crmObject.notes?.length > 0 && 
                                            <><Title level={3}>CRM Notes</Title>
                                            <Collapse
                                                size="small"
                                                items={
                                                    crmObject.notes?.map((x:any)=>{ return { key: x.Note_Title??x.Note_Content.substring(0,50), value: x.Note_Content } })
                                                    .map((x:any) => { 
                                                        return {
                                                            key: x.key,
                                                            label: x.key,
                                                            children: 
                                                                <div 
                                                                style={{ 
                                                                    paddingLeft: '40px', 
                                                                    paddingRight: '40px', 
                                                                    fontSize: '18px' ,
                                                                    wordWrap: 'break-word'
                                                                }} 
                                                                dangerouslySetInnerHTML={{ __html: renderMarkdown(x.value) }} 
                                                            />
                                                        }
                                                    })
                                                }
                                            />
                                            </>}


                                            { visibleCard && <><br></br><Card 
                                                bordered={true}
                                                size="small"
                                                extra={<CloseOutlined onClick={()=>setVisibleCard(undefined)} />}
                                            >
                                                <Card.Meta
                                                    avatar={<Avatar src={visibleCard.source_icon} />}
                                                    title={visibleCard.title}
                                                    description={
                                                        <div
                                                            style={{
                                                                display: '-webkit-box',
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden',
                                                                WebkitLineClamp: 2, // Limit to 3 lines
                                                                lineClamp: 2, // Fallback for other browsers
                                                            }}
                                                        >
                                                            
                                                        </div>
                                                    }
                                                />

                                                {visibleCard.content}
                                            </Card></> }


                                            { marketDataHash.find((x:any)=> x.key === "markets")?.value[0].value?.length > 0 && 
                                            <><Title level={3}>Markets</Title>
                                            <Collapse
                                                size="small"
                                                items={
                                                    marketDataHash.find((x:any)=> x.key === "markets").value[0].value?.map((x:any)=>{ return { key: x.key, value: x.value } })
                                                    .map((x:any) => { 
                                                        return {
                                                            key: x.key,
                                                            label: x.key,
                                                            children: 
                                                                <div 
                                                                style={{ 
                                                                    paddingLeft: '40px', 
                                                                    paddingRight: '40px', 
                                                                    fontSize: '18px' ,
                                                                    wordWrap: 'break-word'
                                                                }} 
                                                                dangerouslySetInnerHTML={{ __html: renderMarkdown(x.value) }} 
                                                            />
                                                        }
                                                    })
                                                }
                                            />
                                            </>}


                                            { marketDataHash.find((x:any)=> x.key === "industries")?.value[0].value?.length > 0 && 
                                            <><Title level={3}>Industries</Title>
                                            <Collapse
                                                size="small"
                                                items={
                                                    marketDataHash.find((x:any)=> x.key === "industries").value[0].value?.map((x:any)=>{ return { key: x.key, value: x.value } })
                                                    .map((x:any) => { 
                                                        return {
                                                            key: x.key,
                                                            label: x.key,
                                                            children: 
                                                                <div 
                                                                style={{ 
                                                                    paddingLeft: '40px', 
                                                                    paddingRight: '40px', 
                                                                    fontSize: '18px' ,
                                                                    wordWrap: 'break-word'
                                                                }} 
                                                                dangerouslySetInnerHTML={{ __html: renderMarkdown(x.value) }} 
                                                            />
                                                        }
                                                    })
                                                }
                                            />   
                                            </>}

                                            { marketDataHash.find((x:any)=> x.key === "services_products")?.value[0].value?.length > 0 && 
                                            <><Title level={3}>Services & Products</Title>
                                            <Collapse
                                                size="small"
                                                items={
                                                    marketDataHash.find((x:any)=> x.key === "services_products").value[0].value?.map((x:any)=>{ return { key: x.key, value: x.value } })
                                                    .map((x:any) => { 
                                                        return {
                                                            key: x.key,
                                                            label: x.key,
                                                            children: 
                                                                <div 
                                                                    style={{ 
                                                                        paddingLeft: '40px', 
                                                                        paddingRight: '40px', 
                                                                        fontSize: '18px' ,
                                                                        wordWrap: 'break-word'
                                                                    }} 
                                                                    dangerouslySetInnerHTML={{ __html: renderMarkdown(x.value) }} 
                                                                />
                                                        }
                                                    })
                                                }
                                            />   
                                            </>}
                                        </div>                     
                                    </Typography>
                                    }
                                </Card>
                            </Col>

                            <Col span={10}>
                                <Card bordered={false}>
                                    <div
                                        style={{
                                            height:'calc(100vh - 100px)', 
                                            // zIndex: 1000
                                        }}
                                    >
                                    <SIKE 
                                        bearerToken={parent.bearerToken} 
                                        user={parent.user} 
                                        context={context} 
                                        thread={thread} 
                                        permission={parent.permission} 
                                        updateCounter={sikeUpdateCounter}
                                        isMobile={parent.isMobile}

                                        prompt={(input: string) => {return {
                                            json: [
                                                { "history": 5 },
                                                { "context": context },
                                                { "thread": thread },
                                                { "model": "gpt-4" },
                                                {
                                                    "prompt": "first",
                                                    "temperature": 0,
    //                                                 "rag": [
    //                                                     { 
    //                                                         "module": '9ac85e2c-4fc0-49e2-b350-6a0c279131f7',
    //                                                         "description": 
    // `1. "EMECO" is a RAG tool.
    // 2. Use the "EMECO" RAG tool to search for any topic or context related to EMECO.
    // 3. If there are more than one subject, create several queries, one query per subject. 
    // 4. For example if the query is about A and/or B then first ask about A then B.`,
    //                                                     }
    //                                                 ],
                                                    "instructions": [
    `You are a business development agent working at Neu Capital.

    Neu Capital Information:
    ${JSON.stringify(toHashTable(neuObj), null, 4)}


    Target company report:
    ${JSON.stringify(marketDataHash, null, 4)}


    CRM Entry:
    ${JSON.stringify(crmObject, null, 4)}
    
    ---
    Use the report to assist the user by responding to the question / statement in a succinct way:
    ${input}
    `
                                                    ]
                                                }
                                            ],
                                            input: input,
                                            agent_reference: 'custom'
                                        }}}
                                        initMessage={`Hello ${parent.user.data.personal.name.firstName}, I am here help you unlock the market. What shall we investigate?`}  
                                    />
                                    </div>
                                </Card>
                            </Col>
                        </Row>                       
                    </Card>
                    
                </div>
                <FloatButton.Group
                    type="default"
                    style={{ 
                        left: 7, 
                        bottom: parent.isMobile ? (openMenu ? 20 : 90) : 20,
                        // position: 'absolute',
                        width: '40px'  
                    }}
                >
                    <FloatButton tooltip={<div>Menu</div>} icon={openMenu ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />} onClick={()=> {setOpenMenu(!openMenu);}} />
                </FloatButton.Group>
            </div>
        </>
    );
}