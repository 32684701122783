import React, { useState, useReducer, useEffect } from 'react';
import { Card, Select, Form, InputNumber, Col, Row, Button, Divider, Typography, Input, Upload, message, Table, DatePicker } from 'antd';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface';

import { getUsers } from '../../utils/users';

import axios from 'axios';

const { TextArea } = Input;

const azure_function_url = process.env.AZURE_FUNCTION_URL;

export const CreateGroup: React.FC<any> = (parent) => {
    const [form] = Form.useForm();
    const [defaultFileList, setDefaultFileList] = useState<any>({data: []});
    const [isLoading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [users, setUsers] = useState<any[]>();
    const [deals, setDeals] = useState<any[]>();

    const [counterpartOptions, setCounterpartOptions] = useState<Record<number, { value: string; label: string }[]>>({});

    useEffect(() => {
        getUsers().then(x=> {
            setUsers(x);
            setCounterpartOptions(prev => ({ ...prev, [0]: (x.map(y=>{ return {value: y.wallet, label: y.name }}) ?? []) }));
        });

        axios.get(
            `${azure_function_url}/LayerC?command=assets_get&chainid=${parent.chainId}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(x => {
                console.log(x.data)
                const assets = x.data.map((x:any)=>{ return { id: x.dealId, name: x.data.name } });

                axios.get(
                    `${azure_function_url}/LayerC?command=liabilities_get&chainid=${parent.chainId}`, 
                    {
                        headers: { 'Authorization': parent.bearerToken }
                    })
                    .then(x => {
                        const liabilities = x.data.map((x:any)=>{ return { id: x.dealId, name: x.data.name } });
                        setDeals(assets.concat(liabilities));
                    })
                    .catch(x=> {
                        // loading = false;
                    });
            })
            .catch(x=> {
                // loading = false;
            });
    },[]);

    const handleMembersChange = (index: any, selectedValue: any) => {
        
        // Dynamically update options for the counterpart field

        const newOptions = selectedValue === 'wallet'
            ? (users?.map(x=>{ return {value: x.wallet, label: x.name }}) ?? [])
            : (deals?.map(x=>{ return { value: x.id, label: x.name + ` (${x.id})` }}) ?? [])//[{ value: 'owner1', label: 'Owner 1' }, { value: 'owner2', label: 'Owner 2' }];

        setCounterpartOptions(prev => ({ ...prev, [index]: newOptions }));
    };



    let myClonedArray:any[] = [];

    const onSend = async (values: any) => {   
        setLoading(true);

        Object.keys(values).forEach(key => { try{ values[key] = values[key].unix(); } catch{} });
        
        axios.post(
            `${azure_function_url}/LayerC?command=register_group&chainid=${parent.chainId}`, 
            {
                name: values.name,
                description: values.description,
                members: values.members.map((member: any)=>{
                    return {
                        address: member.type === 'wallet' ? member.counterpart: '',
                        id: member.type === 'wallet' ? 0 : member.counterpart
                    }
                })
            },
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(x => {
                form.resetFields();
                parent.closeModal();
                setLoading(false);
                setDefaultFileList({data: []});
            });
        
        
    };

    const uploadFile = async (options: UploadRequestOption<any>) => {
        // eslint-disable-next-line
        const { onSuccess, onError, file, onProgress } = options;

        const buffer = await (file as File).arrayBuffer();
        const bytes = new Uint8Array(buffer);
        const b64 = Buffer.from(bytes).toString('base64');

        if(onSuccess){
            myClonedArray.push({ name: (file as File).name, data: b64, created: Date.now() })
            setDefaultFileList({ data: myClonedArray });
        
            onSuccess("Ok");
        }
    };

    const removeFile = async (name: string) => {
        myClonedArray = [];
        defaultFileList.data.forEach((val: any) => {
            if(val.name !== name) myClonedArray.push(Object.assign({}, val))
        }); 
        setDefaultFileList({ data: myClonedArray });
    }

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        customRequest: async (x: any) => { 
            myClonedArray = [];
            if(defaultFileList.data)
                defaultFileList.data.forEach((val: any) => myClonedArray.push(Object.assign({}, val))); 
            await uploadFile(x);
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                forceUpdate();
            } 
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    const formSize = {
        // labelCol: { span: 0 },
        // wrapperCol: { span: 20 },
    }

    return (
        <>
            <Form
                name="create"
                form={form}
                onFinish={onSend}
                initialValues={{}}
                labelCol={{ span: 8 }}
            >
                <Divider orientation="left">Information</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Name" 
                            name="name"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Description" 
                            name="description"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Members</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            // label="Parameters" 
                            {...formSize} 
                        >
                            <div style={{ 
                                width: '100%',                                            
                            }}>
                                <Form.List name={'members'}>
                                    {(fields, { add, remove }) => (
                                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Card 
                                                    size="small"
                                                    key={key}
                                                >
                                                    <div>
                                                        <Form.Item 
                                                            label="Type" 
                                                            name={[name, "type"]}
                                                            rules={[{ required: true, message: 'Please input request' }]}
                                                        >
                                                            <Select
                                                                // defaultValue={'wallet'}
                                                                style={{ width: '100%' }}
                                                                optionFilterProp="label"
                                                                options={[
                                                                    { value: 'wallet', label: 'Wallet'}, 
                                                                    { value: 'owner', label: 'Deal'}
                                                                ]}
                                                                onChange={(value) => handleMembersChange(name, value)}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item 
                                                            label="Counterpart" 
                                                            name={[name, "counterpart"]}
                                                            rules={[{ required: true, message: 'Please input request' }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="label"
                                                                options={counterpartOptions[name] || []}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </Card>
                                            ))}

                                            <Button type="dashed" onClick={() => {
                                                setCounterpartOptions(prev => ({ ...prev, [fields.length+1]: (users?.map(y=>{ return {value: y.wallet, label: y.name }}) ?? []) }));
                                                add({        
                                                    type: 'wallet',
                                                    counterpart: users?.map(x=>x.wallet)[0] ?? ''
                                                })
                                                }} block>
                                            + Add Member
                                            </Button>
                                        </div>
                                    )}
                                    
                                </Form.List>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                
                
                <Divider orientation="left"></Divider>
                <Form.Item>
                    <Button 
                        style={{
                            position: "absolute",
                            width: "120px",
                            left: 0,
                            transform: "translateY(-50%)"
                        }} 
                        onClick={() => { form.resetFields(); setLoading(false); parent.closeModal(); }}
                        disabled={false}
                    >
                        Cancel
                    </Button>

                    <Button 
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        // disabled={!parent.poolData.decimals}
                        style={{
                            position: "absolute",
                            right: 0,
                            width: "120px",
                            transform: "translateY(-50%)"
                        }} 
                    >
                        Create
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}