// App.tsx

import React, { useState, useEffect, useRef } from 'react';

import { Avatar, List, TreeSelect, Alert, Spin, FormInstance, Form, Space, Col, Row, Upload, DatePicker, Menu, Popconfirm, Card, FloatButton, Input, Button, Tabs, message, Select, Modal } from 'antd';
import type { UploadProps, MenuProps } from 'antd';

import { SettingOutlined, EditOutlined, PlusOutlined, FileTextOutlined, SearchOutlined, ApartmentOutlined, BulbOutlined, ExperimentOutlined, MenuFoldOutlined, MenuUnfoldOutlined, LoadingOutlined, SendOutlined, UserOutlined, KeyOutlined, UploadOutlined, CloudDownloadOutlined, DatabaseOutlined, CloseOutlined, CheckOutlined, EllipsisOutlined, PlusCircleOutlined, TeamOutlined, MessageOutlined, DeleteOutlined} from '@ant-design/icons';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import axios from 'axios';
import { marked } from 'marked';

import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';  // Or any other style you prefer

import * as yaml from 'js-yaml';

import { Idea } from '../sike/Idea';

import { v4 as uuidv4 } from 'uuid';

import TurndownService from 'turndown';

// import magicFlow from '../../yml/dreams/magic.yaml';
import magicDeconstruct from '../../yml/dreamflow/magic_deconstruction.yaml';
import schemaYAML from '../../yml/dreamflow/schema_yaml.yaml';
import magicYAML from '../../yml/dreamflow/magic_yaml.yaml';
import allFlow from '../../yml/dreams/all.yaml';
import moduleRAG from '../../yml/rag/module.yaml';
import instructionStrictRAG from '../../yml/rag/instruction_strict.yaml';


const { Item } = Form;

type MenuItem = Required<MenuProps>['items'][number];

const waitOneSecond = (): Promise<void> => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, 1000); // 1000 milliseconds = 1 second
    });
}

const renderMarkdown = (_markdownContent: any) => {

    let markdownContent = '';
    if(Array.isArray(_markdownContent))
        markdownContent = _markdownContent.join('\n');
    else
        markdownContent = _markdownContent;
    
    if(markdownContent.startsWith('```markdown'))
        markdownContent = markdownContent.substring('```markdown'.length);

    if(markdownContent.endsWith('```'))
        markdownContent = markdownContent.substring(0, markdownContent.length - '```'.length);
    
    // Step 1: Convert markdown to HTML
    const htmlContent = marked(markdownContent.toString());

    // Step 2: Parse and highlight code blocks
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find all <pre> elements containing <code> (i.e., code blocks)
    const codeBlockElements = doc.querySelectorAll('pre > code');

    codeBlockElements.forEach(codeElem => {
        const rawCode = codeElem.textContent || '';
        const highlighted = hljs.highlightAuto(rawCode).value;
        codeElem.innerHTML = highlighted;

        // Wrap the code block inside a div with a 'code-card' class
        const wrapper = doc.createElement('div');
        wrapper.className = 'code-card';
        const parentPre = codeElem.parentElement;
        if (parentPre) {
            parentPre.parentNode?.replaceChild(wrapper, parentPre);
            wrapper.appendChild(parentPre);
        }
    });

    // Step 3: Return the updated HTML
    return doc.body.innerHTML;
};

const InputLabel: React.FC<any>  = (parent: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(parent.label);
    const [lastValue, setLastValue] = useState(parent.label);

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    return (
        parent.keyVal === '_search_' ?
            <div 
                style={{ 
                    display: 'flex', 
                    width: '100%', 
                    // padding: '0px !important',
                    // height: '70px',
                    
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    
                    borderRadius: '15px 15px', 
                    border: '1px solid rgba(0, 0, 0, 0.25)', 
                    margin: '10px 10px 20px 0px' 

                }}>
                <Input
                    style={{
                        marginLeft:'7px', 
                        border: '0px',
                        // paddingTop: '10px',
                        // paddingBottom: '10px',
                    }}
                    autoFocus
                    // defaultValue={editValue}
                    onChange={(e) => {
                        e.stopPropagation();
                        parent.setSearch(e.target.value);
                    }}
                />
                <Button
                    type="text"
                    icon={<SearchOutlined />}
                    onClick={(e) => {
                        // e.stopPropagation();
                        // setIsEditing(false);
                        // setLastValue(editValue);

                        // console.log({
                        //     id: parent.keyVal,
                        //     name: editValue
                        // })

                        // axios.post(
                        //     `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                        //     {
                        //         id: parent.keyVal,
                        //         name: editValue
                        //     },
                        //     {
                        //         headers: {
                        //             'Authorization': parent.bearerToken
                        //         }
                        //     }
                        // ).then(res => {
                        //     console.log(res);
                        // }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
            </div>
        :
        isEditing ?
            <div style={{ display: 'flex', width: '100%', padding: '0px !important' }}>
                <Input
                    style={{marginLeft:'7px' }}
                    autoFocus
                    defaultValue={editValue}
                    onChange={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                    }}
                    onPressEnter={(e) => {
                        e.stopPropagation();
                        console.log({
                            id: parent.keyVal,
                            name: editValue
                        })
                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                            {
                                id: parent.keyVal,
                                name: editValue
                            },
                            {
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            }
                        ).then(res => {
                            setIsEditing(false);
                        });
                        
                    }}
                    onBlur={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                        console.log({
                            id: parent.keyVal,
                            name: editValue
                        })
                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                            {
                                id: parent.keyVal,
                                name: e.target.value
                            },
                            {
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            }
                        ).then(res => {
                            setIsEditing(false);
                        });
                    }}
                />
                <Button
                    type="text"
                    icon={<CheckOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                        setLastValue(editValue);

                        console.log({
                            id: parent.keyVal,
                            name: editValue
                        })

                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                            {
                                id: parent.keyVal,
                                name: editValue
                            },
                            {
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            }
                        ).then(res => {
                            console.log(res);
                        }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditValue(lastValue);
                        setIsEditing(false);
                    }}
                />
            </div>
        :
            <div 
                style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    width:'100%',
                    borderRadius: '7px',
                    height: '70px',
                    paddingLeft: parent.selectedDream === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '9px' : '12px', // adjust padding if needed when selected
                    borderLeft: parent.selectedDream === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '3px solid #d9d9d9' : undefined, // add a left border to indicate selection
                    backgroundColor: parent.selectedDream === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '#f5f5f5' : undefined, // light blue background for selection, you can choose any color you prefer
                }}
            >
                {parent.isAddThread ? <></>:parent.menuType === 'threads'?<BulbOutlined/>:<UserOutlined/>}
                <a 
                    style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: parent.keyVal === 'add_dream' || parent.keyVal === 'add_team' ? '' : '10px' }}
                    onClick={(e)=>{

                        if(parent.keyVal === 'add_dream' || parent.keyVal === 'add_team')
                            parent.handleAddClick(e);
                        else{
                            parent.setDream(parent.keyVal);
                            parent.setLabel(parent.label);
                            parent.setDreamContext(parent.context);
                        }                        
                    }}
                >
                    {editValue}
                </a>
                {parent.isAddThread ? 
                    <Button
                        type="text"
                        icon={<PlusCircleOutlined style={{ flexShrink: 1 }}/>}
                        onClick={parent.handleAddClick}
                    /> 
                : parent.menuType === 'threads' ?
                    <>
                        <Button
                            type="text"
                            icon={<EllipsisOutlined style={{ flexShrink: 1 }} />}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent the menu from triggering its default behavior
                                setIsEditing(true);
                            }}
                        />
                        { parent.selectedDream === parent.keyVal ? 
                        <Popconfirm
                            title="Delete Dream"
                            description="Would you like to delete the dream?"
                            onConfirm={(e) => {
                                // e.stopPropagation(); // Prevent the menu from triggering its default behavior
                                setIsEditing(false);

                                axios.get(
                                    `${azure_function_url}/LLMModel?command=context_dream_delete&context=${parent.context}&id=${parent.keyVal}`,
                                    {
                                        headers: {
                                            'Authorization': parent.bearerToken
                                        }
                                    }
                                ).then(res => {
                                    setIsEditing(false);
                                    parent.reload();
                                }).catch(err => {console.log(err);message.error(err.toString())});
                            }}
                            onCancel={() => { }}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            
                        />
                        </Popconfirm>
                        :<></>
                        }
                    </>
                :<></>
                }
            </div>
    );
};

interface CardItem {
    id: string;
    title: string;
    // prompt?: string;
    content: string;
    image: string;
    data: any;
    // loading?: number;
    // newCard?: boolean;
    // checked?: boolean;
    // streamid?: string;
}

export const News: React.FC<any> = (parent) => {

    const [search, setSearch] = useState('');
    const [dream, setDream] = useState('1');
    const [dreamContext, setDreamContext] = useState('1');
    const [label, setLabel] = useState();
    const [menuType, setMenuType] = useState('threads'); // Default active tab key
    const [prompt, setPrompt] = useState<string>(); // Default active tab key
    const [inputPrompt, setInputPrompt] = useState<string>(); // Default active tab key
    const [openKeys, setOpenKeys] = useState(['1']);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [flowIds, setFlowIds] = useState<Record<string,any>>({});

    const [openModal, setOpenModal] = useState(false);

    const [context, setContext] = useState<string>(parent.context);

    const [prompts, setPrompts] = useState<any[]>();
    const [magicStream, setMagicStream] = useState<string>();
    const [magicStream2, setMagicStream2] = useState<string>();
    const [plan, setPlan] = useState<any>();

    const [contextList, setContextList] = useState<any[]>([]);

    const ref_input = useRef(null);
    const ref_send = useRef(null);
    const formRef = useRef<FormInstance>(null);
    const [isLoadingChat, setLoadingChat] = useState({ state: false });
    const messageInputRef = useRef<HTMLDivElement | null>(null);

    const [openMenu, setOpenMenu] = useState(false);

    const [flow, setFlow] = useState();
    const [pid, setPid] = useState('');
    const [flowId, setFlowId] = useState<string | undefined>();
    const [mid, setMid] = useState<string | undefined>();
    
    const [isLoading, setLoading] = useState(false);

    const [tagTree, setTagTree] = useState<any>([]);
    const [selectedNodes, setSelectedNodes] = useState<any>([]);

    const [activeTabKey, setActiveTabKey] = useState('chat'); // Default active tab key

    const [isLoadingFiles, setLoadingFiles] = useState({ state: true});
    const [fileList, setFileList] = useState<any[]>();
    
    const [sikeUpdateCounter, setSikeUpdateCounter] = useState(0); // Default active tab key

    const ref_agent = useRef(null);
    const ref_tags = useRef(null);

    type GroupedPaths = { [key: string]: string[] };

    const groupByPrefix = (paths: string[]): GroupedPaths => {
        const groups: GroupedPaths = {};

        paths.forEach(path => {
            const [prefix, ...rest] = path.split('/');

            // If there's no "/", treat the entire string as a prefix with no sub-elements
            if (rest.length === 0) {
            if (!groups[prefix]) {
                groups[prefix] = [];
            }
            } else {
            const subPath = rest.join('/'); // Re-join the rest of the parts to form the sub-path

            // Initialize the group if it doesn't exist
            if (!groups[prefix]) {
                groups[prefix] = [];
            }

            // Add the sub-path to the appropriate group
            groups[prefix].push(subPath);
            }
        });

        return groups;
    };

    const loadDreamsList = (set_thread = true) => {
        setLoading(true);
        axios.get(
            `${azure_function_url}/LLMModel?command=context_dream_list&context=${context}`, 
            {
                headers: {
                'Authorization': parent.bearerToken
                }
            }).then(res => {
                setLoading(false);
                const it = res.data.map((x:any) => getItem(x.dream === '_flows_' ? 'Workflows' : x.name, x.dream, x.context));
                it.unshift(getItem('New Dream', 'add_dream','', (e: React.Key) => handleAddOnTop(e, it.length)));
                it.unshift(getItem('', '_search_','', (e: React.Key) => handleAddOnTop(e, it.length)));

                setMenuItems(it);

                setFlowIds(flowIds => {
                    if(!flowIds)
                        flowIds = {}
                    res.data.forEach((x: any) => { 
                        flowIds[x.dream] = x.flowid;
                    });
                    return flowIds;
                })

                if(set_thread && res.data.length > 0){
                    setDreamContext(res.data[0].context);
                    setDream(res.data[0].dream);
                    setFlowId(res.data[0].flowid);
                    setLabel(res.data[0].name);
                }
        }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});
    }

    const copyHtmlUsingExecCommand = (htmlContent: string) => {
        const container = document.createElement('div');
        container.innerHTML = htmlContent;
        container.style.position = 'fixed'; // Avoids scrolling to bottom
        document.body.appendChild(container);
        
        const ww = window.getSelection();
        ww?.removeAllRanges();
        const range = document.createRange();
        range.selectNode(container);
        ww?.addRange(range);
      
        try {
          document.execCommand('copy');
          console.log('Content copied');
        } catch (err) {
          console.error('Failed to copy', err);
        }
      
        document.body.removeChild(container);
    }

    const loadIdeasList = () => {
        axios.get(
            `${azure_function_url}/LLMModel?command=context_idea_list&context=${dreamContext}&dream=${dream}`, 
            {
                headers: {
                'Authorization': parent.bearerToken
                }
            }).then(res => {
                if(res.data?.length > 0){
                    try{
                        const inputPrompt = res.data.parameters[0]["value"][0]
                        setInputPrompt(inputPrompt)
                        
                    }
                    catch{
                        setInputPrompt(undefined)
                    }
                    setCards(res.data.map((x:any)=> {
                        return {...x, checked: true};
                    }));
                }
                setFlow(undefined)

        }).catch(err => {console.log(err);message.error(err.toString())});
    }

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        ctx: string,
        addOnTop?: (key: React.Key) => void,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group'
    ): MenuItem => {   
        // Function to call when the Add Thread button is clicked
        const handleAddClick = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation(); // Prevent triggering click on menu item
            addOnTop && addOnTop(key);
        };
      
        // If the key is 'add_dream', we want to add an "Add" button instead of an "Edit" button
        const isAddThread = key === 'add_dream' || key === 'add_team';
        
        return {
            key,
            label: <InputLabel 
                        search={search} 
                        setSearch={setSearch} 
                        bearerToken={parent.bearerToken} 
                        menuType={menuType} 
                        context={ctx} 
                        label={label} 
                        keyVal={key} 
                        selectedDream={dream} 
                        selectedPrompt={prompt} 
                        isAddThread={isAddThread} 
                        handleAddClick={handleAddClick} 
                        setDream={setDream} 
                        setDreamContext={setDreamContext} 
                        setLabel={setLabel} 
                        setPrompt={setPrompt} 
                        reload={loadDreamsList}
                    />,
            type,
        } as MenuItem;
    }
    
    const handleAddOnTop = (key: React.Key,num : number) => {
        if (key === 'add_dream') {

            // const num = menuItems.length
            setInputPrompt(undefined);
            const name = `Dream ${num}`;
            const id = `${Date.now()}`
            const newItem = getItem(name, id, context);

            setMagicStream('');
            setMagicStream2('');

            axios.post(
                `${azure_function_url}/LLMModel?command=context_dream_update&context=${context}`,
                {
                    id: id,
                    name: name
                },
                {
                    headers: {
                        'Authorization': parent.bearerToken
                    }
                }
            ).then(res => {
                setMenuItems((prevItems) => {
                    // Find the index of 'Add Thread'
                    const addThreadIndex = prevItems.findIndex(item => item?.key === 'add_dream');
                    // Insert new item just above 'Add Thread'
                    const newItems = [...prevItems];
                    newItems.splice(addThreadIndex, 0, newItem);
                    return newItems;
                });

                setMagicStream(undefined);
                setMagicStream2(undefined);
                setDream(id);
                setLabel(name as any);
                setDreamContext(parent.user.id);
            }).catch(err => {console.log(err);message.error(err.toString())});       
        }
        else{
            setPrompt('add_');
        }
    };

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const getAllTextAreaValues = () => {
        // Retrieve all form values
        const allValues = formRef.current?.getFieldsValue();
    
        // Extract the prompts array which contains the values of the TextAreas
        const promptsArray = allValues.prompts;
    
        // Check if promptsArray is valid and has items
        if (Array.isArray(promptsArray) && promptsArray.length > 0) {
            // Map through the array and extract the 'prompt' values
            return promptsArray.map(item => item.prompt);
        } else {
            // Return an empty array if no valid data is found
            return [];
        }
    };

    const clearAllTextAreas = () => {
        formRef.current?.setFieldsValue({ prompts: [{ prompt: ' ' }] });
    };

    const decompositionPrompt = () => {

        const description = getAllTextAreaValues();
        
        if(description.length === 0 || description[0].length === 0)
            return;
        
        clearAllTextAreas();

        setMagicStream(undefined);
        
        setLoading(true);
        axios.post(
            `${azure_function_url}/LLMModel?command=dream_deconstruct`, 
            {
                id: flowId,
                model: 'gpt-4',
                dream: dream,
                yaml: magicDeconstruct.replace(/\$_context_\$/g, parent.context ?? parent.user.id),
                parameters: [{
                    id: 'prompt',
                    value: description
                }],
                async: 'true'
            },
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(async res => {

                const _id = res.data;
                setMid(_id);
                setPrompts(undefined);

                const timerDeconstruction = setInterval(() => {
                    axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=Deconstruction`,
                    {
                        headers: { 'Authorization': parent.bearerToken }
                    })
                    .then((data:any) => {
                        if(data.data.finished){

                            // setMagicStream(data.data.content);

                            // setMagicStream(prefix + data.data.content + '...' + suffix);

                            waitOneSecond()
                            .then(() => {
                                axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`,
                                {
                                    headers: { 'Authorization': parent.bearerToken }
                                })
                                .then(res => {
                                    if(res.data == '')
                                        return;
                                    
                                    clearInterval(timerDeconstruction);
                                    const _json : any = filter(res.data.prompts[0].output);

                                    setMagicStream(_json);
                                    
                                    setLoading(false);
                                    setPid(res.data.pkgid);


                                    try{
                                        const inputPrompt = res.data.parameters[0]["value"][0]
                                        setInputPrompt(inputPrompt);
                                        //waitOneSecond().then(() => {structurePrompt(_json, inputPrompt)});
                                    }
                                    catch{
                                        setInputPrompt(undefined);
                                        //waitOneSecond().then(structurePrompt);
                                    }

                                    
                                    
                                    
                                });
                            });
                        }
                        else if(data.data.content !== ''){
                            
                            setMagicStream(data.data.content + '...');
                        }
                    })
                }, 1000);

            
        }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});  
    };

    const structurePrompt = (_magicStream?:any, _inputPrompt?:any) => {

        const _json : any[] = yaml.load(magicYAML.replace(/\$_context_\$/g, parent.context ?? parent.user.id)) as any[];

        axios.post(
            `${azure_function_url}/LLMModel?command=dream_structure`, 
            {
                model: 'gpt-4',
                id: mid,
                dream: dream,
                // yaml: magicYAMLbak.replace(/\$_context_\$/g, parent.context ?? parent.user.id),
                json: _json.map(x => {
                    if(x.prompt){
                        x.instructions = x.instructions.replace(/\$_SCHEMA_YAML_\$/g, schemaYAML)
                    }
                    
                    return x;
                }),

                parameters: [{
                    id: 'Deconstruction',
                    value: _magicStream ?? magicStream
                }],
                async: 'true'

            },
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(async res => {

                const _id = res.data;

                const _id_flow = `${_id}_yaml`;

                const timerYAML_Formatter = setInterval(() => {
                    axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id_flow}&pid=YAML_Formatter`,
                    {
                        headers: { 'Authorization': parent.bearerToken }
                    })
                    .then(data => {
   
                        if(data.data.finished){
                            clearInterval(timerYAML_Formatter);    
                            waitOneSecond()
                            .then(() => {
                                axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id_flow}`,
                                {
                                    headers: { 'Authorization': parent.bearerToken }
                                })
                                .then(res => {
                                    setLoading(false);
                                    const prefix = '```yaml\n';

                                    setPlan(res.data.prompts[0].output);
                            
                                    setMagicStream2(prefix + res.data.prompts[0].output);

                                    //waitOneSecond().then(() => { executePrompt(res.data.prompts[0].output, _inputPrompt) } );
                                    
                                }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});   
                            });
                        }
                        else if(data.data.content !== ''){
                            const prefix = '```yaml\n';
                            const suffix = '\n```';

                            setMagicStream2(prefix + data.data.content + '...' + suffix);
                            // scrollToBottom();
                        }
                    })                    
                }, 1000);
            });
    };

    const cleanInputPrompt = (input: string): string => {
        // Split the string into lines, trim each line, and rejoin with proper indentation (4 spaces for example)
        const cleaned = input
          .split('\n')
          .map(line => line.trim())  // Trim leading/trailing spaces
          .map(line => `    ${line}`)  // Add 4-space indentation for YAML compliance
          .join('\n');  // Join back into a multi-line string
        
        return cleaned;
    }

    const executePrompt = (_plan?:any, _inputPrompt?:any) => {
        setCards([]);
        
        axios.post(
            `${azure_function_url}/LLMModel?command=dream_execute`, 
            {
                id: mid,
                model: 'gpt-4',
                dream: dream,
                yaml: (_plan ?? plan).replace(/\$_context_\$/g, parent.context ?? parent.user.id).replace(/\$_input_\$/g, cleanInputPrompt(_inputPrompt ?? inputPrompt ?? '')),
                async: 'true'

            },
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(async res => {
                const _id = res.data;

                const _id_flow = `${_id}_dream`;

                const prompts = (yaml.load(_plan ?? plan) as any[]).filter(x=>x.prompt);

                const _cards = prompts.map((p: any) => { return { id: p.prompt, title: p.prompt.replace(/_/g, ' '), prompt: p.instructions[0].instruction, checked: true, loading: 1, streamid: _id_flow } as any});
                _cards.unshift({
                    id: uuidv4(), 
                    title: 'Main Context',
                    //prompt: _inputPrompt ?? inputPrompt ?? '',
                    content: _inputPrompt ?? inputPrompt ?? '',
                    checked: true,
                    loading: 0,
                    streamid: _id_flow
                });

                setPrompts(prompts?.map((p:any)=>{ return { prompt:p?.prompt, output: '' }}));
                setCards(_cards);
            });
    };

    const sendPrompt = () => {
        const input = getAllTextAreaValues();

        const turndownService = new TurndownService();

        const filteredYAML = allFlow.replace(/\$_context_\$/g, parent.context ?? parent.user.id);
        const flow_json = 
            (yaml.load(filteredYAML) as any[])
            .map(x=>{
                if(x.prompt === "first"){
                    if(selectedNodes && Object.keys(selectedNodes)?.length > 0){
                        x.rag = Object.keys(selectedNodes).map((module: any) => {
                            
                            const rag:any = yaml.load(moduleRAG.replace(/\$_name_\$/g, module));
                            const tags: any = selectedNodes[module];
                            if(tags) 
                                rag.tags = tags.map((path:any) => path.split('/').pop());
                                
                            return rag;
                        });
                    }

                    x.instructions = [ x.instructions
                        .replace(/\$_instruction_rag_\$/g, selectedNodes && Object.keys(selectedNodes)?.length > 0 ? instructionStrictRAG : '')
                        // .replace(/\$_all_\$/g, JSON.stringify(cards.filter(x=>x.checked && (x.content || x.prompt)).map(x=>turndownService.turndown((x.content ?? x.prompt) as string)),null, 4))
                        .replace(/\$_input_\$/g, input) ];
                }

                return x;
            });

        clearAllTextAreas();
        const fid = uuidv4();
        setLoading(true);
        axios.post(
            `${azure_function_url}/LLMModel?command=flow`, 
            {
                model: 'gpt-4',
                id: fid,
                json: flow_json,
                save: 'false',
                async: 'true'
            },  
            {
                headers: {
                'Authorization': parent.bearerToken
                }
        }).then(res => {
            const _id = res.data;
            
            const timer = setInterval(() => {
                try{
                
                    axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=${"first"}`,
                    {
                        headers: {
                        'Authorization': parent.bearerToken
                        }
                    })
                    .then(async data => {
                        if(data.data.finished){
                            clearInterval(timer);

                            await waitOneSecond();
                            axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`,
                            {
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            })
                            .then(data => {
                                if(data.data?.prompts){
                                    setFlow(data.data.prompts[0].output);
                                    
                                    setLoading(false);
                                }
                            })
                            .catch(err=>{console.log(err)});
                        }
                        else{
                            setFlow(data.data.content + '...' as any);
                        }
                    });
                }
                catch{
                    clearInterval(timer);
                    waitOneSecond().then(() => {
                        axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`,
                        {
                            headers: {
                            'Authorization': parent.bearerToken
                            }
                        })
                        .then(data => {
                            if(data.data?.prompts){
                                setFlow(data.data.prompts[0].output);
                                setLoading(false);
                            }
                        })
                    });
                }

            }, 250);                
        }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});    
    };
    
    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        accept: '.pdf, .ts, .md, .docx',
        defaultFileList: fileList,
        customRequest: async (options: any) => { 
            
            // eslint-disable-next-line
            const { onSuccess, onError, file, onProgress } = options;
            const buffer = await (file as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);

            const fmData = new FormData();

            setLoadingFiles({ state: true });
    
            fmData.append("file", new Blob([bytes], {type:file.type}), file.name);

            const res = await axios.post(
                `${azure_function_url}/LLMModel?command=files_get_data&context=${context}`,
                fmData,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        "Content-Type": `multipart/form-data; boundery=${(fmData as any)._boundary}`,
                        'Authorization': parent.bearerToken
                    },
                }
            )

            // const fileData = res.data[0].pageContent;
            const fileData = res.data.reduce((acc:any, curr:any) => acc + '\\n\\n' + curr.pageContent, '');

            formRef.current?.setFieldsValue({ prompts: [ { prompt: fileData } ]});

            const files = res.data.map((row: any, i: number) => {
                return {
                    uid: i,
                    id: row.id,
                    name: row.name,
                    status: 'done',
                    data: row.data,
                    added: row.added,
                    last_read: row.last_read,
                    initialChunkSize: row.initialchunksize
                };
            }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);

            setLoadingFiles({ state: false });
            setFileList(files);
            setSikeUpdateCounter(x=>x=x+1);

            if(onSuccess){
                onSuccess("Ok");
            }            
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            }
            else if (status === 'removed') {
                message.success(`${info.file.name} file removed successfully.`);
            }
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    useEffect(()=>{
        axios.get(
            `${azure_function_url}/LLMModel?command=news_get`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(async res => { 
                console.log(res.data)
                // setCards(res.data.map((x: any)=>{ 
                //     return {
                //         id: x.data.article_id,
                //         title: x.data.title,
                //         content: x.data.description,
                //         image: x.data.image_url
                //     }
                // }));
                setCards(res.data);
            });
    }, []);

    useEffect(()=>{

        // axios.get(
        //     `${azure_function_url}/LLMModel?command=news_update`, 
        //     {
        //         headers: { 'Authorization': parent.bearerToken}
        //     })
        //     .then(x => { 
        //         console.log(x.data);
        //     });

        axios.get(
            `${azure_function_url}/LLMModel?command=context_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(async (x) => {
            setContextList(x.data.concat({ id: parent.user.id, name: 'Personal' }));
            // console.log(x.data)

        });    
    }, []);

    useEffect(() => {
        const _id = flowId;
        if(!flowId)
            return;

        setMagicStream('thinking...');
        setMagicStream2('thinking...');

        // setMagicStream(undefined);
        // setMagicStream2(undefined);
        setPlan(undefined);

        setMid(_id)

        const prefix = '```yaml\n';
        const suffix = '\n```';

        const timerDeconstruction = setInterval(() => {
            axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=Deconstruction`,
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then((data:any) => {
                    
                if(data.data.finished){
                    clearInterval(timerDeconstruction);

                    waitOneSecond()
                    .then(() => {
                        axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`,
                        {
                            headers: { 'Authorization': parent.bearerToken }
                        })
                        .then(res => {
                            if(res.data == '')
                                return;
                            const _json : any = filter(res.data.prompts[0].output);

                            setMagicStream(_json);
                            
                            try{
                                const inputPrompt = res.data.parameters[0]["value"][0]
                                setInputPrompt(inputPrompt);
                            }
                            catch{
                                setInputPrompt(undefined);
                            }
                            
                            setLoading(false);
                            setPid(res.data.pkgid);

                            ///
                            
                        });
                    });
                }
                else if(data.data.content !== ''){
                    setMagicStream(prefix + data.data.content + '...' + suffix);
                }
            
            })
        }, 1000);

        const _id_flow = `${_id}_yaml`;

        const timerYAML_Formatter = setInterval(() => {
            axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id_flow}&pid=YAML_Formatter`,
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(data => {
                if(data.data.finished){
                    clearInterval(timerYAML_Formatter);    
                    waitOneSecond()
                    .then(() => {
                        axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id_flow}`,
                        // axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}_dream`,
                        {
                            headers: { 'Authorization': parent.bearerToken }
                        })
                        .then(res => {
                            setLoading(false);
                            
                            const _json : any = filter(res.data.prompts[0].output);
                            setPlan(_json);
                            setMagicStream2(prefix + _json + suffix);
                            
                        }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});   
                    });
                }
                else if(data.data.content !== ''){
                    setMagicStream2(prefix + data.data.content + '...' + suffix);
                    // scrollToBottom();
                }
            })                    
        }, 1000);

        // const timerDeconstruction = setInterval(() => {
        //     axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=Deconstruction`,
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(data => {
        //         setLoading(true);
        //         if(data.data.finished){
        //             clearInterval(timerDeconstruction);
        //             setLoading(false);
        //         }
        //         else if(data.data.content !== ''){
        //             setMagicStream(data.data.content + '...');                    
        //         }
                
        //     });            
        // }, 1000);

        // const timerYAML_Formatter = setInterval(() => {
        //     axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=YAML_Formatter`,
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(data => {
        //         setLoading(true);

        //         const prefix = '```yaml\n';
        //         const suffix = '\n```';

        //         if(data.data.finished){
        //             clearInterval(timerYAML_Formatter);

        //             waitOneSecond()
        //             .then(() => {
        //                 axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}_dream`,
        //                 {
        //                     headers: { 'Authorization': parent.bearerToken }
        //                 })
        //                 .then(res => {
        //                     console.log(res)
        //                     const _json : any = yaml.load(filter(res.data.prompts[1].output))
        //                     const prompts = _json.filter((x:any)=>x.prompt).map((x:any)=>{return {...x, instructions: [ { instruction: x.instructions }]}})

        //                     const _id_flow = `${_id}_dream`;

        //                     try{
        //                         const inputPrompt = res.data.parameters[0]["value"][0]
        //                         setInputPrompt(inputPrompt)   
        //                     }
        //                     catch{
        //                         setInputPrompt(undefined)
        //                     }

        //                     setCards(prompts.map((p: any) => { return { id: p.prompt, title: p.prompt.replace(/_/g, ' '), prompt: p.instructions[0].instruction, loading: 1, streamid: _id_flow, checked: true }}));
                            
        //                     setLoading(false);
        //                     setPid(res.data.pkgid);
        //                     // setMagicStream(undefined);
        //                     setMagicStream(prefix + res.data.content + '...' + suffix);
                            
        //                     setPrompts(prompts?.map((p:any)=>{ return { prompt:p?.prompt, output: '' }}));
        //                 }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});   
        //             });
        //         }
        //         else if(data.data.content !== '') {
                    
        //             setMagicStream(prefix + data.data.content + '...' + suffix);
        //             // scrollToBottom();
        //         }
                
        //     });            
            
        // }, 1000);
    }, [flowId])

    useEffect(() => {
        setMagicStream('thinking...');
        setMagicStream2('thinking...');
        setPlan(undefined);

        setMagicStream(undefined);
        setMagicStream2(undefined);

        setLoading(false);
        setFlowId(flowIds[dream]);
        setFlow(undefined);

        if(parent.isMobile)
            setOpenMenu(false);

        if(dream === '1'){
            loadDreamsList(dream === '1');
            setCards([]);
        }
        else{
            const it = menuItems.map((x:any) => {
                if(x.key !== 'add_dream' && x.key !== '_search_')
                    return getItem(dream === '_flows_' ? 'Workflows' : x.label.props.label, x.key, x.label.props.context);
                else
                    return x;
            });
            setMenuItems(it);
        }
    },[dream]);

    useEffect(() => {
        if(context)
            axios.get(
                `${azure_function_url}/LLMModel?command=tree&context=${context}`, 
                {
                    headers: { 'Authorization': parent.bearerToken }
                })
                .then(res => {
                    if (res.data !== null && res.data !== undefined && res.data?.length > 0)
                        setTagTree(res.data)
                })
                .catch(err => {console.log(err);message.error(err.toString())});
    }, [context]);

    // useEffect(() => {
    //     if(dream !== '1'){
    //         setLoading(true);
    //         axios.get(
    //             `${azure_function_url}/LLMModel?command=context_idea_list&context=${dreamContext}&dream=${dream}`, 
    //             {
    //                 headers: { 'Authorization': parent.bearerToken }
    //             })
    //             .then(res => {
    //                 setLoading(false);
    //                 if(res.data?.length > 0){
    //                     try{
    //                         const inputPrompt = res.data[0].parameters[0]["value"][0]
    //                         setInputPrompt(inputPrompt);
    //                     }
    //                     catch{
    //                         setInputPrompt(undefined);
    //                     }

    //                     setCards(res.data.map((x: any)=>{ return {...x, checked: true}}));
    //                 }
    //                 else
    //                     setCards([]);
    //             })
    //             .catch(err => {console.log(err);message.error(err.toString())});
    //     }
        
    // },[context, dream]);

    useEffect(() => {
        if(parent.context === 'ideas')
            setContext(parent.user.id);
        else
            setContext(parent.context);
    }, [parent.context]);

    const initialCards: CardItem[] = [];

    const filter = (text:string) => {
        // Define the prefixes and suffixes to look for
        const prefix = '```yaml';
        const suffix = '```';

        // Check if the text starts with the prefix and remove it
        if (text.startsWith(prefix)) {
            text = text.substring(prefix.length);
        }

        // Check if the text ends with the suffix and remove it
        if (text.endsWith(suffix)) {
            text = text.substring(0, text.length - suffix.length);
        }

        // Return the modified text
        return text.trim();

    }

    const [cards, setCards] = useState<CardItem[]>(initialCards);

    const onDragEnd = (result : any) => {
        const { destination, source } = result;

        if (!destination) {
        return;
        }

        if (destination.index === source.index && destination.droppableId === source.droppableId) {
        return;
        }

        const reorderedCards = Array.from(cards);
        const [removed] = reorderedCards.splice(source.index, 1);
        reorderedCards.splice(destination.index, 0, removed);

        const newOrder = reorderedCards.map((c,i)=>{ return { ...c, idx: i, checked: true}})

        setCards(newOrder);
    };

    const handleMessageSend = async () => {
        if(cards.length === 0)
            await decompositionPrompt();
        else
            await sendPrompt();
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        // const textarea = event.currentTarget;

        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // prevent creating a new line
            handleMessageSend();
        }

        // else if (event.key === 'Tab') {
        //     event.preventDefault();

        //     const spaces = "    "; // 4 spaces for a tab, you can adjust this
        //     const start = textarea.selectionStart;
        //     const end = textarea.selectionEnd;
        //     const selectedText = textarea.value.substring(start, end);

        //     if (start !== end) {
        //         // Text is selected. Indent each line of the selected text.
        //         const indentedText = selectedText.split('\n').map(line => spaces + line).join('\n');
        //         textarea.value = textarea.value.substring(0, start)
        //             + indentedText
        //             + textarea.value.substring(end);
                
        //         // Adjust the selection to highlight the newly indented text
        //         textarea.selectionStart = start;
        //         textarea.selectionEnd = start + indentedText.length;
        //     } else {
        //         // No text selected. Insert spaces for a tab.
        //         textarea.value = textarea.value.substring(0, start)
        //             + spaces
        //             + textarea.value.substring(end);

        //         // Position the caret after the inserted spaces
        //         textarea.selectionStart = textarea.selectionEnd = start + spaces.length;
        //     }
        // }
    };

    return (
        <>
            <div 
                style={{  
                    position: 'relative', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    height: 'calc(100vh - 65px)' 
                }}
            >
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={(keys) => {
                        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                        if (latestOpenKey){
                            setOpenKeys(keys);
                        } else {
                            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
                        }
                    }}
                    style={{ 
                        display: openMenu ? undefined: 'none',
                        width: parent.isMobile ? '100%' : 400, 
                        fontSize: '25px',
                        fontWeight: 100,
                        
                        position: 'sticky',
                        top: parent.isMobile ? undefined : '100px', // Adjust the value as needed to position the menu correctly
                        overflowY: 'auto' 
                    }}
                    items={menuItems.filter((x:any)=> x.key === '_search_' || x.key === 'add_dream' || x.label.props.label.toLowerCase().indexOf(search?.toLowerCase()) > -1)}
                />
                    <div 
                        style={{ 
                            display: openMenu && parent.isMobile ? 'none' :  undefined,
                            position: 'relative', 
                            height:'100%', 
                            width: '100%', 
                            marginRight: parent.isMobile ? undefined :  '55px', 
                            marginLeft: parent.isMobile || openMenu ? undefined:  '55px',
                        }}
                    >
                        <div 
                            className='custom-card' 
                            style={{ 
                                
                                width:  '100%', 
                                height: '100%',
                                
                            }}
                        >
                            <Card 
                                bordered={false} 
                                className='custom-card'
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflow: 'auto' // Prevents overflow outside the Card
                                }}
                            >
                                
                                { isLoading ?
                                <>
                                <Spin tip="Loading...">
                                    <Alert
                                        message="Working on it..."
                                        description="I need a second to work my magic!"
                                        type="info"
                                    />
                                </Spin>
                                <br></br>
                                </>
                                :
                                <>
                                
                                </>
                                }
                                { !isLoading && inputPrompt ?
                                <Alert
                                        message="Magic Prompt"
                                        description={inputPrompt}
                                        type="info"
                                    />
                                :<></>
                                }

                                <div style={{ height:'calc(100vh - 155px)' }}>
                                    { !isLoading ?
                                    <>
                                        <div 
                                            style={{ 
                                                display: 'flex', 
                                                
                                                flexDirection: 'column', // Added for vertical alignment
                                                alignItems: 'center', // Center items horizontally
                                                justifyContent: 'center', // Center items vertically
                                
                                                width: 'calc(100% - 0px)',
                                                boxSizing: 'border-box',
                                                backgroundColor: 'white',
                                                borderRadius: '15px 15px', 
                                                border: '1px solid rgba(0, 0, 0, 0.25)', 
                        
                                                margin: '0px 10px 20px 0px' 
                                            }}
                                        >
                                            <Form 
                                                ref={formRef} 
                                                style={{ 
                                                    width: '100%', 
                                                    display: 'flex', 
                                                    flexDirection: 'row', 
                                                    margin: '10px',
                                                }} 
                                                initialValues={{prompts:[{prompt:''}]}}
                                            >
                                                <Button 
                                                    type="primary" 
                                                    icon={(isLoadingChat.state ? <LoadingOutlined />:<></>)} 
                                                    style={{ 
                                                        color: 'rgba(0, 0, 0, 0.75)',
                                                        backgroundColor: 'transparent', 
                                                        borderColor: 'transparent', 
                                                        borderRadius: '0 0 0 0',
                                                        alignSelf: 'flex-end',
                                                        marginBottom: '0px',
                                                        boxShadow:'none',                                            
                                                    }}
                                                />
                                                
                                                <Item style={{ flexGrow: 1, marginBottom: 0, marginRight: '-1px' }}>
                                                    <div ref={ref_input} style={{ width: '100%' }}>
                                                        <div ref={messageInputRef} ></div>
                                                        <Form.List name="prompts">
                                                            {(fields, { add, remove }) => (
                                                                <>
                                                                    {fields.map(({ key, name, ...restField }) => (
                                                                        <div key={key} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, 'prompt']}
                                                                                style={{ flex: 1, marginBottom: fields.length === 1 ? 0 : undefined, maxHeight: '400px', overflow: 'auto' }}
                                                                            >
                                                                                <Idea
                                                                                    key={key}
                                                                                    placeholder="How can I help you?" 
                                                                                    disabled={isLoadingChat.state}
                                                                                    noborder
                                                                                    handleKeyDown={handleKeyDown}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </Form.List>
                                                    </div>
                                                </Item>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Upload {...props}>
                                                        <Button
                                                            type="primary" 
                                                            icon={<PlusOutlined />}
                                                            style={{ 
                                                                color: 'rgba(0, 0, 0, 0.75)',
                                                                backgroundColor: 'transparent'
                                                            }}
                                                        />
                                                    </Upload>
                                                    <Space.Compact 
                                                        style={{ 
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                            flexGrow: 1,
                                                            marginBottom: 0,
                                                            marginRight: '-1px',
                                                        }}
                                                    >
                                                        { cards.length > 0 && tagTree && tagTree.length > 0 && (
                                                        <div ref={ref_tags}>
                                                            <Item name={'tags'} noStyle>
                                                                <TreeSelect
                                                                    style={{ 
                                                                        minWidth: parent.isMobile ? undefined : '50px',

                                                                        width: parent.isMobile ? '50px' : undefined,// '200px',
                                                                    }} 
                                                                    dropdownStyle={{ 
                                                                        minWidth: parent.isMobile ? '300px' : '450px',
                                                                        maxWidth: '50%',
                                                                    }}
                                                                    // placeholder={'Select knodules...'}
                                                                    placeholder={<ApartmentOutlined />}
                                                                    multiple={true}
                                                                    onChange={e => e && setSelectedNodes(groupByPrefix(e))} 
                                                                    treeData={tagTree}
                                                                />  
                                                            </Item>
                                                        </div>
                                                        )}
                                                        
                                                    </Space.Compact>
                                                    <Button
                                                        ref={ref_send}
                                                        type="primary" 
                                                        icon={cards.length === 0 ? <ExperimentOutlined /> : <SendOutlined />} 
                                                        onClick={() => { handleMessageSend() }}
                                                        style={{ 
                                                            color: 'rgba(0, 0, 0, 0.75)',
                                                            backgroundColor: 'transparent'
                                                        }}
                                                    />
                                                </div>
                                            </Form>
                                        </div>
                                        </>  
                                        :<></>
                                    }
                                    { magicStream && 
                                        <div 
                                            style={{
                                                width: 'calc(100% - 0px)',
                                                overflow: 'auto', // Prevents overflow from the Idea section
                                                margin: '0px 20px 20px 0px' 
                                            }}>
                                                <Idea title={'Thinking...'} dream={dream} setContent={(ct:any)=> { console.log(ct); setMagicStream(ct)}} value={magicStream} handleDelete={()=>{setMagicStream(undefined)}} />
                                                <br></br>
                                                <Button 
                                                    type="primary" 
                                                    icon={<SearchOutlined />} 
                                                    onClick={()=>{
                                                        structurePrompt();
                                                    }}>
                                                    Structure
                                                </Button>
                                        </div> 
                                    }

                                    { magicStream2 && 
                                        <div 
                                            style={{
                                                width: 'calc(100% - 0px)',
                                                overflow: 'auto', // Prevents overflow from the Idea section
                                                margin: '0px 20px 20px 0px' 
                                            }}>
                                                <Idea title={'Thinking...'} dream={dream} setContent={(ct:any)=> { console.log(ct); setMagicStream2('```yaml\n' + ct); setPlan(ct); }} value={magicStream2} handleDelete={()=>{setMagicStream2(undefined)}} />
                                                <br></br>
                                                <Button 
                                                    type="primary" 
                                                    icon={<SearchOutlined />} 
                                                    onClick={()=>{
                                                        executePrompt();
                                                    }}>
                                                    Execute
                                                </Button>
                                        </div> 
                                    }
                                    
                                    { flow && 
                                        <div style={{
                                            width: 'calc(100% - 0px)',
                                            overflow: 'auto', // Prevents overflow from the Idea section
                                            margin: '0px 20px 20px 0px' 
                                        }}>
                                            <Idea 
                                                isMobile={parent.isMobile}
                                                title={'Output'} 
                                                value={flow} 
                                                loading={isLoading} 
                                                outputCard 
                                                card 
                                                placeholder={'EMPTY'} 
                                                handleDelete={()=>{setFlow(undefined)}} 
                                                handleNewCard={()=>{
                                                    axios.post(
                                                        `${azure_function_url}/LLMModel?command=context_idea_update&context=${dreamContext}`,
                                                        {
                                                            id: uuidv4(),
                                                            dream: dream,
                                                            idx: cards.length + 1,
                                                            title: 'Light bulb moment',
                                                            content: marked(flow),
                                                        },
                                                        {
                                                            headers: { 'Authorization': parent.bearerToken }
                                                        })
                                                        .then(async res => {
                                                            loadIdeasList();
                                                            // setFlow(undefined);
                                                            // console.log(res)
                                                        })

                                                    // setCards(cards => 
                                                    //     // cards.concat({'id': uuidv4(), 'title': 'New Idea', 'content': flow, 'newCard': false })
                                                    //     cards.concat({'id': uuidv4(), 'title': 'New Idea', 'content': flow })

                                                    //     // ...cards.slice(0, cards.length - 1), // This copies all elements of the array except the last one
                                                    //     // {'id': uuidv4(), 'title': 'New Idea', 'content': flow, 'newCard': false }, // This adds your new item at the next-to-last position
                                                    //     // cards[cards.length - 1] // This adds the last item back to the end of the array
                                                    // ); 
                                                }}
                                            />
                                        </div> 
                                    }
                                    { !isLoading && 
                                        <div 
                                            style={{ 
                                                flex: 1, 
                                                // overflowY: 'auto',
                                            }}
                                        > 
                                            {parent.isMobile ? 
                                            <>
                                            <List
                                                style={{ 
                                                    width:'100%',
                                                    height: 'calc(100% - 60px)',
                                                    // height: 'calc(100% - 160px)',
                                                    overflow: 'auto',
                                                    position: 'absolute',

                                                    flexGrow: 1
                                                }}
                                                itemLayout="vertical"
                                                loading={isLoadingChat.state}
                                                dataSource={cards.map((x,i)=>{return {...x, key:i}})}
                                                renderItem={(card: any, index : number) => (
                                                    <>
                                                        <br></br>
                                                        <List.Item 
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row', // Horizontal layout
                                                                borderBottom: index === cards.length - 1 ? 'none' : '1px solid black',
                                                                alignItems: 'flex-start' // Align items at the start of the flex container
                                                            }}
                                                        >
                                                            <Idea 
                                                                key={card.id}
                                                                id={card.id} 
                                                                title={card.title} 
                                                                value={card.content} 
                                                                loading={card.loading === 1}
                                                                streamid={card.streamid}
                                                                checked={card.checked}
                                                                setChecked={(ch:boolean)=>{card.checked=ch}}
                                                                setContent={(ct:any)=> {card.content=ct}}
                                                                card 
                                                                newCard={card.newCard}
                                                                idx={index}
                                                                dream={dream}
                                                                context={dreamContext}
                                                                placeholder={ card.prompt } 
                                                                onChange={((val: string) => {
                                                                    if(val !== card.content){
                                                                        console.log('set card')
                                                                        // setCards(cards => cards.map(_c => {if(_c.id === card.id) { _c.content = val } return _c; }))
                                                                    }
                                                                })} 
                                                                handleDelete={loadIdeasList}
                                                                user={parent.user}
                                                                bearerToken={parent.bearerToken}
                                                                isMobile={parent.isMobile}
                                                            />
                                                        </List.Item>     
                                                    </>
                                                )}
                                            />
                                            </>
                                            :
                                            <>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="droppableCards">
                                                {(provided) => (
                                                    <Row style={{ display: 'flex', justifyContent: 'space-between' }} {...provided.droppableProps} ref={provided.innerRef} gutter={[16, 16]}>
                                                    {cards.map((card, index) => (

                                                        <Draggable key={card.id} draggableId={card.id} index={index}>
                                                        {(provided) => (
                                                            <Col key={card.id} className='custom-idea' span={8} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <Card
                                                                    // style={{ width: 595 }}
                                                                    style={{ flex: '1 1 30%', height: 500 }}
                                                                    cover={
                                                                        <img
                                                                            style={{ 
                                                                                height: 350, 
                                                                                width: '100%', 
                                                                                objectFit: 'cover' 
                                                                            }}
                                                                            alt="example"
                                                                            src={card.data.image_url}
                                                                        />
                                                                    }
                                                                    actions={[
                                                                        <SettingOutlined key="setting" />,
                                                                        <EditOutlined key="edit" />,
                                                                        <EllipsisOutlined key="ellipsis" />,
                                                                    ]}
                                                                >
                                                                    <Card.Meta
                                                                        avatar={<Avatar src={card.data.source_icon} />}
                                                                        title={card.data.title}
                                                                        description={
                                                                            <div
                                                                                style={{
                                                                                    display: '-webkit-box',
                                                                                    WebkitBoxOrient: 'vertical',
                                                                                    overflow: 'hidden',
                                                                                    WebkitLineClamp: 2, // Limit to 3 lines
                                                                                    lineClamp: 2, // Fallback for other browsers
                                                                                }}
                                                                            >
                                                                                {card.data.description ?? card.data.content}
                                                                            </div>
                                                                        }
                                                                    />
                                                                </Card>
                                                                {/* <Idea 
                                                                    key={card.id}
                                                                    id={card.id} 
                                                                    title={card.title} 
                                                                    value={card.content} 
                                                                    loading={card.loading === 1}
                                                                    streamid={card.streamid}
                                                                    card 
                                                                    checked={card.checked}
                                                                    setChecked={(ch:boolean)=>{card.checked=ch}}
                                                                    setContent={(ct:any)=> {card.content=ct}}
                                                                    newCard={card.newCard}
                                                                    idx={index}
                                                                    dream={dream}
                                                                    context={dreamContext}
                                                                    placeholder={ card.prompt } 
                                                                    onChange={((val: string) => {
                                                                        if(val !== card.content){
                                                                            console.log('set card')
                                                                            // setCards(cards => cards.map(_c => {if(_c.id === card.id) { _c.content = val } return _c; }))
                                                                        }
                                                                    })} 
                                                                    handleDelete={loadIdeasList}
                                                                    user={parent.user}
                                                                    bearerToken={parent.bearerToken}
                                                                    isMobile={parent.isMobile}
                                                                /> */}
                                                            </Col>
                                                        )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                    </Row>
                                                )}
                                                </Droppable>
                                            </DragDropContext>
                                            </>}
                                        </div>
                                    }
                                </div>                                
                            </Card>
                        </div>
                    </div>
                <Modal 
                    width={1500} 
                    open={openModal} 
                    closable={true} 
                    onCancel={() => setOpenModal(false)} 
                    okText={'Copy'}
                    onOk={()=> {
                        copyHtmlUsingExecCommand(renderMarkdown(cards.map(x=>`\n# ${x.title} \n ${x.content}`).join('\n<br/>\n')));
                        setOpenModal(false);
                    }}>
                    <div dangerouslySetInnerHTML={{ __html: renderMarkdown(cards.map(x=>`\n# ${x.title} \n ${x.content}`).join('\n<br/>\n')) }} ></div>
                </Modal>

                <FloatButton.Group
                    type="default"
                    style={{ left: 7, bottom: 35 }}
                >
                    <FloatButton tooltip={<div>Menu</div>} icon={openMenu ? <MenuFoldOutlined  /> : <MenuUnfoldOutlined />} onClick={()=> {setOpenMenu(!openMenu);}} />
                </FloatButton.Group>
                <FloatButton.Group
                    type="default"
                    style={{ right: 7, bottom: 35 }}
                >
                    <FloatButton 
                        tooltip={<div>Document</div>} 
                        icon={<FileTextOutlined  />} 
                        onClick={()=> {setOpenModal(true)}} />

                    <FloatButton 
                        tooltip={<div>New Idea</div>} 
                        icon={<PlusCircleOutlined />} 
                        onClick={()=> {setCards(cards => [
                            // ...cards.concat([{'id': uuidv4(), 'title': 'Light bulb moment' }])
                        ]); }} />
                </FloatButton.Group>
            </div>
        </>
    );
}