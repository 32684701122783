
import * as yaml from 'js-yaml';
import coreFlow from '../yml/sike/core.yaml';
import documentsFlow from '../yml/sike/documents.yaml';
import moduleRAG from '../yml/rag/module.yaml';
import instructionStrictRAG from '../yml/rag/instruction_strict.yaml';
import instructionRelaxedRAG from '../yml/rag/instruction_relaxed.yaml';

export const gptModel = 'gpt-4';

export const getPrompt = (context: string, thread: string, agent: string, input: string, web: boolean, tags?: string[]) => {
    if(agent === 'GPT4'){
        const core_yaml = coreFlow
            .replace(/\$_history_\$/g, 5)
            .replace(/\$_context_\$/g, context)
            .replace(/\$_thread_\$/g, thread)
            .replace(/\$_model_\$/g, gptModel)
            .replace(/\$_temperature_\$/g, prompts[agent].temperature)

        const core_json : any[] = yaml.load(core_yaml) as any[];
            
        return {
            json: core_json.map(x => {
                if(x.prompt === "first"){
                    x.instructions = [ x.instructions.replace(/\$_input_\$/g, input) ];
                    x.web = web;
                }
                return x;
            }),
            input: input,
            agent_reference: agent
        }
    }
    else if(agent === 'chat'){
        const documents_yaml = documentsFlow
            .replace(/\$_history_\$/g, 5)
            .replace(/\$_context_\$/g, context)
            .replace(/\$_thread_\$/g, thread)
            .replace(/\$_model_\$/g, gptModel)
            .replace(/\$_temperature_\$/g, prompts[agent].temperature)

        const documents_json : any[] = yaml.load(documents_yaml) as any[];
        return {
            json: documents_json.map(x => {
                if(x.prompt === "first"){

                    x.web = web;
                    x.rag = [1].map((_: any) => {
                        const rag:any = yaml.load(moduleRAG.replace(/\$_name_\$/g, context));
                        if(tags) 
                            rag.tags = tags.map((path:any) => path.split('/').pop());
                            
                        return rag;
                    });
                

                    x.instructions = [ 
                        x.instructions
                            .replace(/\$_rag_instructions_\$/g, instructionStrictRAG) 
                            .replace(/\$_input_\$/g, input) 
                    ];
                }
                return x;
            }),
            input: input,
            agent_reference: agent
        }
    }
    else if(agent === 'hybrid'){
        const hybrid_yaml = documentsFlow
            .replace(/\$_history_\$/g, 5)
            .replace(/\$_context_\$/g, context)
            .replace(/\$_thread_\$/g, thread)
            .replace(/\$_model_\$/g, gptModel)
            .replace(/\$_temperature_\$/g, prompts[agent].temperature)

        const hybrid_json : any[] = yaml.load(hybrid_yaml) as any[];
        return {
            json: hybrid_json.map(x => {
                if(x.prompt === "first"){

                    x.rag = [1].map((_: any) => {
                        const rag:any = yaml.load(moduleRAG.replace(/\$_name_\$/g, context));
                        if(tags) 
                            rag.tags = tags.map((path:any) => path.split('/').pop());
                            
                        return rag;
                    });
                

                    x.instructions = [ 
                        x.instructions
                            .replace(/\$_rag_instructions_\$/g, instructionRelaxedRAG) 
                            .replace(/\$_input_\$/g, input) 
                    ];
                }
                return x;
            }),
            input: input,
            agent_reference: agent
        }
    }
    
    
}

export const prompts: Record<string, any> = {
    GPT4: {
        label: 'GPT',
        temperature: 0
    },
    chat: {
        label: 'Documents',
        temperature: 0
    }
};