
export const getUsers = async () => {
    return [
        {
            id: '31e16e59-6860-493b-9196-ab05353aaff6',
            wallet: '0x3ffaEd2e6fE7B16FB997b873F100C4c830314621',
            email: 'tenant@layer-c.io',
            name: 'Tenant'
        },
        {
            id: 'bd8a0b4f-443f-4529-bacc-23f4a08d1e95',
            wallet: '0x574D1135a10E91006eC937eFD2b29FC5B99F18a0',
            email: 'owner@layer-c.io',
            name: 'Owner'
        }
    ]
}