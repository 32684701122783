// App.tsx

import React, { useState, useEffect, useRef } from 'react';

import { List, Alert, Spin, FormInstance, Form, Col, Row, Menu, Popconfirm, Card, FloatButton, Input, Button, Tabs, message, Select, Modal } from 'antd';
import type { MenuProps } from 'antd';

import { FileTextOutlined, SearchOutlined, BulbOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, KeyOutlined, CloseOutlined, CheckOutlined, EllipsisOutlined, PlusCircleOutlined, DeleteOutlined, SaveOutlined} from '@ant-design/icons';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Employees } from '../employees/Employees';

import axios from 'axios';
import { marked } from 'marked';

import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';  // Or any other style you prefer

import * as yaml from 'js-yaml';

import { Idea } from '../sike/Idea';
import { Input as SInput } from '../sike/Input';

import { v4 as uuidv4 } from 'uuid';

import TurndownService from 'turndown';

import magicFlow from '../../yml/dreams/magic.yaml';
import allFlow from '../../yml/dreams/all.yaml';
import moduleRAG from '../../yml/rag/module.yaml';
import instructionStrictRAG from '../../yml/rag/instruction_strict.yaml';

type MenuItem = Required<MenuProps>['items'][number];


const renderMarkdown = (_markdownContent: any) => {

    let markdownContent = '';
    if(Array.isArray(_markdownContent))
        markdownContent = _markdownContent.join('\n');
    else
        markdownContent = _markdownContent;
    
    if(markdownContent.startsWith('```markdown'))
        markdownContent = markdownContent.substring('```markdown'.length);

    if(markdownContent.endsWith('```'))
        markdownContent = markdownContent.substring(0, markdownContent.length - '```'.length);
    
    // Step 1: Convert markdown to HTML
    const htmlContent = marked(markdownContent.toString());

    // Step 2: Parse and highlight code blocks
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find all <pre> elements containing <code> (i.e., code blocks)
    const codeBlockElements = doc.querySelectorAll('pre > code');

    codeBlockElements.forEach(codeElem => {
        const rawCode = codeElem.textContent || '';
        const highlighted = hljs.highlightAuto(rawCode).value;
        codeElem.innerHTML = highlighted;

        // Wrap the code block inside a div with a 'code-card' class
        const wrapper = doc.createElement('div');
        wrapper.className = 'code-card';
        const parentPre = codeElem.parentElement;
        if (parentPre) {
            parentPre.parentNode?.replaceChild(wrapper, parentPre);
            wrapper.appendChild(parentPre);
        }
    });

    // Step 3: Return the updated HTML
    return doc.body.innerHTML;
};

const InputLabel: React.FC<any>  = (parent: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(parent.label);
    const [lastValue, setLastValue] = useState(parent.label);

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    return (
        parent.keyVal === '_search_' ?
            <div 
                style={{ 
                    display: 'flex', 
                    width: '100%', 
                    // padding: '0px !important',
                    // height: '70px',
                    
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    
                    borderRadius: '15px 15px', 
                    border: '1px solid rgba(0, 0, 0, 0.25)', 
                    margin: '10px 10px 20px 0px' 

                }}>
                <Input
                    style={{
                        marginLeft:'7px', 
                        border: '0px',
                        // paddingTop: '10px',
                        // paddingBottom: '10px',
                    }}
                    autoFocus
                    // defaultValue={editValue}
                    onChange={(e) => {
                        e.stopPropagation();
                        parent.setSearch(e.target.value);
                    }}
                />
                <Button
                    type="text"
                    icon={<SearchOutlined />}
                    onClick={(e) => {
                        // e.stopPropagation();
                        // setIsEditing(false);
                        // setLastValue(editValue);

                        // console.log({
                        //     id: parent.keyVal,
                        //     name: editValue
                        // })

                        // axios.post(
                        //     `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                        //     {
                        //         id: parent.keyVal,
                        //         name: editValue
                        //     },
                        //     {
                        //         headers: {
                        //             'Authorization': parent.bearerToken
                        //         }
                        //     }
                        // ).then(res => {
                        //     console.log(res);
                        // }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
            </div>
        :
        isEditing ?
            <div style={{ display: 'flex', width: '100%', padding: '0px !important' }}>
                <Input
                    style={{marginLeft:'7px' }}
                    autoFocus
                    defaultValue={editValue}
                    onChange={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                    }}
                    onPressEnter={(e) => {
                        e.stopPropagation();
                        console.log({
                            id: parent.keyVal,
                            name: editValue
                        })
                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                            {
                                id: parent.keyVal,
                                name: editValue
                            },
                            {
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            }
                        ).then(res => {
                            setIsEditing(false);
                        });
                        
                    }}
                    onBlur={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                        console.log({
                            id: parent.keyVal,
                            name: editValue
                        })
                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                            {
                                id: parent.keyVal,
                                name: e.target.value
                            },
                            {
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            }
                        ).then(res => {
                            setIsEditing(false);
                        });
                    }}
                />
                <Button
                    type="text"
                    icon={<CheckOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                        setLastValue(editValue);

                        console.log({
                            id: parent.keyVal,
                            name: editValue
                        })

                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                            {
                                id: parent.keyVal,
                                name: editValue
                            },
                            {
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            }
                        ).then(res => {
                            console.log(res);
                        }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditValue(lastValue);
                        setIsEditing(false);
                    }}
                />
            </div>
        :
            <div 
                style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    width:'100%',
                    borderRadius: '7px',
                    height: '70px',
                    paddingLeft: parent.selectedDream === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '9px' : '12px', // adjust padding if needed when selected
                    borderLeft: parent.selectedDream === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '3px solid #d9d9d9' : undefined, // add a left border to indicate selection
                    backgroundColor: parent.selectedDream === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '#f5f5f5' : undefined, // light blue background for selection, you can choose any color you prefer
                }}
            >
                {parent.isAddThread ? <></>:parent.menuType === 'threads'?<BulbOutlined/>:<UserOutlined/>}
                <a 
                    style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: parent.keyVal === 'add_dream' || parent.keyVal === 'add_team' ? '' : '10px' }}
                    onClick={(e)=>{

                        if(parent.keyVal === 'add_dream' || parent.keyVal === 'add_team')
                            parent.handleAddClick(e);
                        else{
                            parent.setDream(parent.keyVal);
                            parent.setLabel(parent.label);
                            parent.setDreamContext(parent.context);
                        }                        
                    }}
                >
                    {editValue}
                </a>
                {parent.isAddThread ? 
                    <Button
                        type="text"
                        icon={<PlusCircleOutlined style={{ flexShrink: 1 }}/>}
                        onClick={parent.handleAddClick}
                    /> 
                : parent.menuType === 'threads' ?
                    <>
                        <Button
                            type="text"
                            icon={<EllipsisOutlined style={{ flexShrink: 1 }} />}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent the menu from triggering its default behavior
                                setIsEditing(true);
                            }}
                        />
                        { parent.selectedDream === parent.keyVal ? 
                        <Popconfirm
                            title="Delete Dream"
                            description="Would you like to delete the dream?"
                            onConfirm={(e) => {
                                // e.stopPropagation(); // Prevent the menu from triggering its default behavior
                                setIsEditing(false);

                                axios.get(
                                    `${azure_function_url}/LLMModel?command=context_dream_delete&context=${parent.context}&id=${parent.keyVal}`,
                                    {
                                        headers: {
                                            'Authorization': parent.bearerToken
                                        }
                                    }
                                ).then(res => {
                                    setIsEditing(false);
                                    parent.reload();
                                }).catch(err => {console.log(err);message.error(err.toString())});
                            }}
                            onCancel={() => { }}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            
                        />
                        </Popconfirm>
                        :<></>
                        }
                    </>
                :<></>
                }
            </div>
    );
};

interface CardItem {
    id: string;
    title?: string;
    prompt?: string;
    content?: string;
    loading?: number;
    newCard?: boolean;
    checked?: boolean;
    streamid?: string;
    vec_id?: string;
}

export const Dreams: React.FC<any> = (parent) => {

    const [search, setSearch] = useState('');
    const [dream, setDream] = useState('1');
    const [dreamContext, setDreamContext] = useState('1');
    const [label, setLabel] = useState();
    const [menuType, setMenuType] = useState('threads'); // Default active tab key
    const [prompt, setPrompt] = useState<string>(); // Default active tab key
    const [inputPrompt, setInputPrompt] = useState<string>(); // Default active tab key
    const [openKeys, setOpenKeys] = useState(['1']);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [flowIds, setFlowIds] = useState<Record<string,any>>({});

    const [openModal, setOpenModal] = useState(false);

    const [context, setContext] = useState<string>(parent.context);

    const [prompts, setPrompts] = useState<any[]>();
    const [magicStream, setMagicStream] = useState<string>();

    const [contextList, setContextList] = useState<any[]>([]);

    const ref_input = useRef(null);
    const ref_send = useRef(null);
    const formRef = useRef<FormInstance>(null);
    const [isLoadingChat, setLoadingChat] = useState({ state: false });
    const messageInputRef = useRef<HTMLDivElement | null>(null);

    const [openMenu, setOpenMenu] = useState(!parent.isMobile);

    const [flow, setFlow] = useState();
    const [pid, setPid] = useState('');
    const [flowId, setFlowId] = useState<string | undefined>();
    
    const [isLoading, setLoading] = useState(false);


    const [activeTabKey, setActiveTabKey] = useState('chat'); // Default active tab key

    const ref_agent = useRef(null);
    const ref_tags = useRef(null);

    const loadDreamsList = (set_thread = true) => {
        setLoading(true);
        axios.get(
            `${azure_function_url}/LLMModel?command=context_dream_list&context=${context}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                setLoading(false);
                const it = res.data.map((x:any) => getItem(x.dream === '_flows_' ? 'Workflows' : x.name, x.dream, x.context));
                it.unshift(getItem('New Dream', 'add_dream','', (e: React.Key) => handleAddOnTop(e, it.length)));
                it.unshift(getItem('', '_search_','', (e: React.Key) => handleAddOnTop(e, it.length)));

                setMenuItems(it);

                setFlowIds(flowIds => {
                    if(!flowIds)
                        flowIds = {}
                    res.data.forEach((x: any) => { 
                        flowIds[x.dream] = x.flowid;
                    });
                    return flowIds;
                })

                if(set_thread && res.data.length > 0){
                    setDreamContext(res.data[0].context);
                    setDream(res.data[0].dream);
                    // setFlowId(res.data[0].flowid);
                    setLabel(res.data[0].name);
                }
            }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});
    }

    const copyHtmlUsingExecCommand = (htmlContent: string) => {
        const container = document.createElement('div');
        container.innerHTML = htmlContent;
        container.style.position = 'fixed'; // Avoids scrolling to bottom
        document.body.appendChild(container);
        
        const ww = window.getSelection();
        ww?.removeAllRanges();
        const range = document.createRange();
        range.selectNode(container);
        ww?.addRange(range);
      
        try {
          document.execCommand('copy');
          console.log('Content copied');
        } catch (err) {
          console.error('Failed to copy', err);
        }
      
        document.body.removeChild(container);
    }

    const loadIdeasList = () => {
        axios.get(
            `${azure_function_url}/LLMModel?command=context_idea_list&context=${dreamContext}&dream=${dream}`, 
            {
                headers: {
                'Authorization': parent.bearerToken
                }
            }).then(res => {
                
                if(res.data?.length > 0){
                    try{
                        const inputPrompt = res.data.parameters[0]["value"][0]
                        setInputPrompt(inputPrompt)
                        
                    }
                    catch{
                        setInputPrompt(undefined)
                    }
                    setCards(res.data.map((x:any)=> {
                        return {...x, checked: true};
                    }));
                }
                setFlow(undefined)

        }).catch(err => {console.log(err);message.error(err.toString())});
    }

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        ctx: string,
        addOnTop?: (key: React.Key) => void,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group'
    ): MenuItem => {   
        // Function to call when the Add Thread button is clicked
        const handleAddClick = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation(); // Prevent triggering click on menu item
            addOnTop && addOnTop(key);
        };
      
        // If the key is 'add_dream', we want to add an "Add" button instead of an "Edit" button
        const isAddThread = key === 'add_dream' || key === 'add_team';
        
        return {
            key,
            label: <InputLabel 
                        search={search} 
                        setSearch={setSearch} 
                        bearerToken={parent.bearerToken} 
                        menuType={menuType} 
                        context={ctx} 
                        label={label} 
                        keyVal={key} 
                        selectedDream={dream} 
                        selectedPrompt={prompt} 
                        isAddThread={isAddThread} 
                        handleAddClick={handleAddClick} 
                        setDream={setDream} 
                        setDreamContext={setDreamContext} 
                        setLabel={setLabel} 
                        setPrompt={setPrompt} 
                        reload={loadDreamsList}
                    />,
            type,
        } as MenuItem;
    }
    
    const handleAddOnTop = (key: React.Key,num : number) => {
        if (key === 'add_dream') {

            // const num = menuItems.length
            setInputPrompt(undefined);
            const name = `Dream ${num}`;
            const id = `${Date.now()}`
            const newItem = getItem(name, id, context);

            axios.post(
                `${azure_function_url}/LLMModel?command=context_dream_update&context=${context}`,
                {
                    id: id,
                    name: name
                },
                {
                    headers: {
                        'Authorization': parent.bearerToken
                    }
                }
            ).then(res => {
                setMenuItems((prevItems) => {
                    // Find the index of 'Add Thread'
                    const addThreadIndex = prevItems.findIndex(item => item?.key === 'add_dream');
                    // Insert new item just above 'Add Thread'
                    const newItems = [...prevItems];
                    newItems.splice(addThreadIndex, 0, newItem);
                    return newItems;
                });

                setDream(id);
                setLabel(name as any);
                setDreamContext(parent.user.id);
            }).catch(err => {console.log(err);message.error(err.toString())});       
        }
        else{
            setPrompt('add_');
        }
    };

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const getAllTextAreaValues = () => {
        // Retrieve all form values
        const allValues = formRef.current?.getFieldsValue();
    
        // Extract the prompts array which contains the values of the TextAreas
        const promptsArray = allValues.prompts;
    
        // Check if promptsArray is valid and has items
        if (Array.isArray(promptsArray) && promptsArray.length > 0) {
            // Map through the array and extract the 'prompt' values
            const textAreaValues = promptsArray.map(item => item.prompt);
            return textAreaValues;
        } else {
            // Return an empty array if no valid data is found
            return [];
        }
    };

    const clearAllTextAreas = () => {
        formRef.current?.setFieldsValue({ prompts: [{ prompt: ' ' }] });
    };

    const waitOneSecond = (): Promise<void> => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 1000); // 1000 milliseconds = 1 second
        });
    }

    const magicPrompt = (data: any[]) => {
        
        const description = getAllTextAreaValues();
        
        if(data.length === 0 && (description.length === 0 || description[0].length === 0))
            return;
        
        clearAllTextAreas();

        console.log(parent.context ?? parent.user.id)
        console.log(parent)
        
        setLoading(true);
        axios.post(
            `${azure_function_url}/LLMModel?command=dream`, 
            {
                model: 'gpt-4',
                dream: dream,
                yaml: magicFlow.replace(/\$_context_\$/g, parent.context ?? parent.user.id),
                parameters: [{
                    id: 'prompt',
                    value: description
                }],
                async: 'true',
                files: data?.map(x=> { return {...x,graph:false, vectorise:false}})
            },
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(async res => {

                const _id = res.data;
                setPrompts(undefined);

                console.log('_ID: ', _id, description, description[0].trim().replace(/[^\w\s]/gi, ''))

               
                if(description && description.length > 0 && description[0].trim().replace(/[^\w\s]/gi, '').length > 0){
                    const timerDeconstruction = setInterval(() => {
                        axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=Deconstruction`,
                        {
                            headers: { 'Authorization': parent.bearerToken }
                        })
                        .then((data:any) => {
                            console.log(data)
                            if(data.data.finished){

                                clearInterval(timerDeconstruction);

                                const timerYAML_Formatter = setInterval(() => {
                                    axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=YAML_Formatter`,
                                    {
                                        headers: { 'Authorization': parent.bearerToken }
                                    })
                                    .then(data => {
                                        
                                        if(data.data.finished){

                                            clearInterval(timerYAML_Formatter);

                                            waitOneSecond()
                                            .then(() => {
                                                axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`,
                                                {
                                                    headers: { 'Authorization': parent.bearerToken }
                                                })
                                                .then(res => {
                                                    console.log(res.data)
                                                    const _json : any = yaml.load(filter(res.data.prompts[1].output))
                                                    const prompts = _json.filter((x:any)=>x.prompt).map((x:any)=>{return {...x, instructions: [ { instruction: x.instructions }]}})
            
                                                    const _id_flow = `${_id}_dream/`;

                                                    try{
                                                        const inputPrompt = res.data.parameters[0]["value"][0]
                                                        setInputPrompt(inputPrompt);
                                                    }
                                                    catch{
                                                        setInputPrompt(undefined);
                                                    }
            
                                                    console.log('-------- YAML 1')
                                                    setCards(prompts.map((p: any) => { return { id: p.prompt, title: p.prompt.replace(/_/g, ' '), prompt: p.instructions[0].instruction, checked: true, loading: 1, streamid: _id_flow }}));
                                                    
                                                    setLoading(false);
                                                    setPid(res.data.pkgid);
                                                    setMagicStream(undefined);
                                                    
                                                    setPrompts(prompts?.map((p:any)=>{ return { prompt:p?.prompt, output: '' }}));
            
                                                }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});   
                                            });
                                        }
                                        if(data.data.content !== ''){
                                            const prefix = '```yaml\n';
                                            const suffix = '\n```';

                                            setMagicStream(prefix + data.data.content + '...' + suffix);
                                            // scrollToBottom();
                                        }
                                    })                    
                                }, 1000);
                            }
                            else if(data.data.content !== ''){
                                setMagicStream(data.data.content + '...');
                                
                                // scrollToBottom();
                            }
                        })
                    }, 1000);          
                }  
                else{
                    const timerAll = setInterval(() => {
                        axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=__all__`,
                        {
                            headers: { 'Authorization': parent.bearerToken }
                        })
                        .then((data:any) => {
                            console.log(data.data)
                            if(data.data.finished){

                                clearInterval(timerAll);

                                waitOneSecond()
                                .then(() => {
                                    
                                    axios.get(
                                        `${azure_function_url}/LLMModel?command=context_idea_list&context=${dreamContext}&dream=${dream}`, 
                                        {
                                            headers: { 'Authorization': parent.bearerToken }
                                        })
                                        .then(res => {
                                            
                                            if(res.data?.length > 0){
                                                try{
                                                    const inputPrompt = res.data.parameters[0]["value"][0]
                                                    setInputPrompt(inputPrompt);
                                                }
                                                catch{
                                                    setInputPrompt(undefined)
                                                }
                                                
                                                setCards(res.data.map((x:any)=> {
                                                    return {...x, checked: true};
                                                }));
                                            }
                                            
                                            setLoading(false);
                                            
                                            setMagicStream(undefined);
                                            setPrompts(prompts?.map((p:any)=>{ return { prompt:p?.prompt, output: '' }}));
                            
                                        }).catch(err => {console.log(err);message.error(err.toString())});
                                });
                            }
                            else{
                                setMagicStream(data.data.content);
                            }
                        });
                    }, 1000);
    
                }
        }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});  
    }

    const sendPrompt = (data: any[]) => {
        const input = getAllTextAreaValues();

        const turndownService = new TurndownService();

        const filteredYAML = allFlow.replace(/\$_context_\$/g, parent.context ?? parent.user.id);
        const flow_json = 
            (yaml.load(filteredYAML) as any[])
            .map(x=>{
                if(x.prompt === "first"){

                    const allValues = formRef.current?.getFieldsValue();
                    const webSearch = allValues.websearch;
                    const useRag = allValues.agent === 'chat';
                    const selectedNodes = useRag && (!allValues.tags || allValues.tags?.length === 0) ? [parent.user.id] : allValues.tags;
                    if(selectedNodes && selectedNodes?.length > 0){
                        x.rag = selectedNodes.map((module: any) => {
                            
                            const rag:any = yaml.load(moduleRAG.replace(/\$_name_\$/g, module));
                            const tags: any = selectedNodes[module];
                            if(tags) 
                                rag.tags = tags.map((path:any) => path.split('/').pop());
                                
                            return rag;
                        });
                    }

                    x.web = webSearch;

                    x.instructions = [ (webSearch || selectedNodes && selectedNodes?.length > 0 ? instructionStrictRAG : '') + '\n\n' + x.instructions
                        // .replace(/\$_instruction_rag_\$/g, selectedNodes && selectedNodes?.length > 0 ? instructionStrictRAG : '')
                        .replace(/\$_all_\$/g, JSON.stringify(cards.filter(x=>x.checked && (x.content || x.prompt)).map(x=>turndownService.turndown((x.content ?? x.prompt) as string)),null, 4))
                        .replace(/\$_input_\$/g, input) ];
                    
                    console.log(selectedNodes, useRag, allValues)
                    console.log(x)
                }

                return x;
            });

        clearAllTextAreas();
        const fid = uuidv4();
        setLoading(true);
        axios.post(
            `${azure_function_url}/LLMModel?command=flow`, 
            {
                model: 'gpt-4',
                id: fid,
                json: flow_json,
                save: 'false',
                async: 'true',
                files: data
            },  
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                const _id = res.data;
                
                const timer = setInterval(() => {
                    try{
                    
                        axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=${"first"}`,
                        {
                            headers: { 'Authorization': parent.bearerToken }
                        })
                        .then(async data => {
                            if(data.data.finished){
                                clearInterval(timer);

                                await waitOneSecond();
                                axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`,
                                {
                                    headers: {
                                        'Authorization': parent.bearerToken
                                    }
                                })
                                .then(data => {
                                    if(data.data?.prompts){
                                        setFlow(data.data.prompts[0].output);
                                        
                                        setLoading(false);

                                        if(data && parent.onFinished){
                                            parent.onFinished();
                                        }
                                    }
                                })
                                .catch(err=>{console.log(err)});
                            }
                            else{
                                setFlow(data.data.content + '...' as any);
                            }
                        });
                    }
                    catch{
                        clearInterval(timer);
                        waitOneSecond()
                        .then(() => {
                            axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`,
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            })
                            .then(data => {
                                if(data.data?.prompts){
                                    setFlow(data.data.prompts[0].output);
                                    setLoading(false);
                                }
                            })
                        });
                    }

                }, 250);           
        }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});    
    };

    useEffect(()=>{
        axios.get(
            `${azure_function_url}/LLMModel?command=context_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: { 'Authorization': parent.bearerToken }
            }
            ).then(async (x) => {
                setContextList(x.data.concat({ id: parent.user.id, name: 'Personal' }));
            });    
    }, []);

    useEffect(() => {
        const _id = flowId;
        if(!flowId)
            return;

        const timerDeconstruction = setInterval(() => {
            axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=Deconstruction`,
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(data => {
                setLoading(true);
                if(data.data.finished){
                    clearInterval(timerDeconstruction);
                    setLoading(false);
                }
                else if(data.data.content !== ''){
                    setMagicStream(data.data.content + '...');
                    // scrollToBottom();
                }
                
            });            
        }, 1000);

        const timerYAML_Formatter = setInterval(() => {        
            axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=YAML_Formatter`,
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(data => {
                setLoading(true);
                if(data.data.finished){
                    clearInterval(timerYAML_Formatter);

                    waitOneSecond()
                    .then(() => {
                        axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`,
                        {
                            headers: { 'Authorization': parent.bearerToken }
                        })
                        .then(res => {
                            if(res.data?.prompts?.length > 1){
                                const _json : any = yaml.load(filter(res.data.prompts[1].output))
                                const prompts = _json.filter((x:any)=>x.prompt).map((x:any)=>{return {...x, instructions: [ { instruction: x.instructions }]}})

                                const _id_flow = `${_id}_dream/`;

                                try{
                                    const inputPrompt = res.data.parameters[0]["value"][0]
                                    setInputPrompt(inputPrompt)
                                    
                                }
                                catch{
                                    setInputPrompt(undefined)
                                }

                                console.log('-------- YAML 2', prompts)
                                setCards(prompts.map((p: any) => { return { id: p.prompt, title: p.prompt.replace(/_/g, ' '), prompt: p.instructions[0].instruction, loading: 1, streamid: _id_flow, checked: true }}));
                                
                                setLoading(false);
                                setPid(res.data.pkgid);
                                setMagicStream(undefined);
                                
                                setPrompts(prompts?.map((p:any)=>{ return { prompt:p?.prompt, output: '' }}));
                            }
                        }).catch(err => {setLoading(false);console.log(err);message.error(err.toString())});   
                    });
                }
                else if(data.data.content !== '') {
                    
                    const prefix = '```yaml\n';
                    const suffix = '\n```';

                    setMagicStream(prefix + data.data.content + '...' + suffix);
                    // scrollToBottom();
                }
                
            });            
            
        }, 1000);
    }, [flowId])

    useEffect(() => {
        setLoading(false);
        // setFlowId(flowIds[dream]);
        setFlow(undefined);

        if(parent.isMobile)
            setOpenMenu(false);

        if(dream === '1'){
            loadDreamsList(dream === '1');
            setCards([]);
        }
        else{
            const it = menuItems.map((x:any) => {
                if(x.key !== 'add_dream' && x.key !== '_search_')
                    return getItem(dream === '_flows_' ? 'Workflows' : x.label.props.label, x.key, x.label.props.context);
                else
                    return x;
            });
            setMenuItems(it);
        }
    },[dream]);

    useEffect(() => {
        if(dream !== '1'){
            setLoading(true);
            axios.get(
                `${azure_function_url}/LLMModel?command=context_idea_list&context=${dreamContext}&dream=${dream}`, 
                {
                    headers: { 'Authorization': parent.bearerToken }
                })
                .then(res => {
                    setLoading(false);
                    if(res.data?.length > 0){
                        try{
                            const inputPrompt = res.data[0].parameters[0]["value"][0]
                            setInputPrompt(inputPrompt)
                            
                        }
                        catch{
                            setInputPrompt(undefined)
                        }

                        console.log(res.data)

                        setCards(res.data.map((x: any)=>{ return {...x, checked: true}}));
                    }
                    else
                        setCards([]);

            }).catch(err => {console.log(err);message.error(err.toString())});
        }
        
    },[context, dream]);

    useEffect(() => {
        if(parent.context === 'ideas')
            setContext(parent.user.id);
        else
            setContext(parent.context);
    }, [parent.context]);

    const initialCards: CardItem[] = [];

    const filter = (text:string) => {
        // Define the prefixes and suffixes to look for
        const prefix = '```yaml';
        const suffix = '```';

        // Check if the text starts with the prefix and remove it
        if (text.startsWith(prefix)) {
            text = text.substring(prefix.length);
        }

        // Check if the text ends with the suffix and remove it
        if (text.endsWith(suffix)) {
            text = text.substring(0, text.length - suffix.length);
        }

        // Return the modified text
        return text.trim();

    }

    const [cards, setCards] = useState<CardItem[]>(initialCards);

    const onDragEnd = (result : any) => {
        const { destination, source } = result;

        if (!destination) {
        return;
        }

        if (destination.index === source.index && destination.droppableId === source.droppableId) {
        return;
        }

        const reorderedCards = Array.from(cards);
        const [removed] = reorderedCards.splice(source.index, 1);
        reorderedCards.splice(destination.index, 0, removed);

        const newOrder = reorderedCards.map((c,i)=>{ return { ...c, idx: i, checked: true}})

        console.log('-------- NEW ORDER')
        setCards(newOrder);
    };

    const handleMessageSend = async (data: any[]) => {
        if(cards.length === 0)
            await magicPrompt(data);
        else
            await sendPrompt(data);
    };

    // const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    //     // const textarea = event.currentTarget;

    //     if (event.key === 'Enter' && !event.shiftKey) {
    //         event.preventDefault(); // prevent creating a new line
    //         handleMessageSend();
    //     }
    // };

    return (
        <>
            <div 
                style={{  
                    position: 'relative', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    height: 'calc(100vh - 65px)' 
                }}
            >
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={(keys) => {
                        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                        if (latestOpenKey){
                            setOpenKeys(keys);
                        } else {
                            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
                        }
                    }}
                    style={{ 
                        display: openMenu ? undefined: 'none',
                        width: parent.isMobile ? '100%' : 400, 
                        fontSize: '25px',
                        fontWeight: 100,
                        
                        position: 'sticky',
                        top: parent.isMobile ? undefined : '100px', // Adjust the value as needed to position the menu correctly
                        overflowY: 'auto' 
                    }}
                    items={menuItems.filter((x:any)=> x.key === '_search_' || x.key === 'add_dream' || x.label.props.label.toLowerCase().indexOf(search?.toLowerCase()) > -1)}
                />

                <div style={{ 
                    display: openMenu && parent.isMobile ? 'none' :  undefined,
                    position: 'relative', 
                    height:'100%', 
                    width: '100%', 
                    marginRight: parent.isMobile ? undefined :  '55px', 
                    marginLeft: parent.isMobile || openMenu ? undefined:  '55px',
                }}>
                    <div 
                        className='custom-card' 
                        style={{ 
                            
                            width:  '100%', 
                            height: '100%',
                            
                        }}
                    >
                        <Card 
                            bordered={false} 
                            className='custom-card'
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'auto' // Prevents overflow outside the Card
                            }}
                        >
                            
                            { isLoading ?
                            <>
                            <Spin tip="Loading...">
                                <Alert
                                    message="Working on it..."
                                    description="I need a second to work my magic!"
                                    type="info"
                                />
                            </Spin>
                            <br></br>
                            </>
                            :
                            <>
                            
                            </>
                            }
                            { !isLoading && inputPrompt ?
                            <Alert
                                    message="Magic Prompt"
                                    description={inputPrompt}
                                    type="info"
                                />
                            :<></>
                            }

                            <Tabs
                                activeKey={activeTabKey}
                                defaultActiveKey="1"
                                onChange={(key) => { 
                                    if(key === 'teams')
                                        setMenuType('teams');
                                    else
                                        setMenuType('threads');
                                    setActiveTabKey(key); 
                                }}
                                type="card"
                                size={parent.isMobile ? "small" : "large"}
                                
                                items={[{
                                    label: (
                                        <span style={{
                                            fontSize: parent.isMobile ? undefined : '22px', 
                                            fontWeight: 100
                                        }}>
                                            <BulbOutlined />
                                            {label}
                                        </span>
                                        ),
                                    key: 'chat',
                                    children:
                                    <div style={{ height:'calc(100vh - 155px)' }}>
                                        { !isLoading ?
                                        <>
                                            <SInput
                                                handleMessageSend={handleMessageSend}
                                                isLoadingChat={isLoadingChat.state}
                                                isMobile={parent.isMobile}
                                                prompt={parent.prompt}
                                                formRef={formRef}
                                                messageInputRef={messageInputRef}
                                                bearerToken={parent.bearerToken}
                                                context={parent.context}
                                                thread={parent.thread}
                                                updateCounter={parent.updateCounter}

                                                ref_input={ref_input}
                                                ref_agent={ref_agent}
                                                ref_tags={ref_tags}
                                                ref_send={ref_send}

                                                bottom={false}
                                                tree={true}

                                                agent={'GPT'}
                                            />
                                        </>
                                        :<></>
                                        }
                                        { magicStream && (<div style={{
                                                width: 'calc(100% - 0px)',
                                                overflow: 'auto', // Prevents overflow from the Idea section
                                                margin: '0px 20px 20px 0px' 
                                            }}>
                                                <Idea title={'Thinking...'} value={magicStream} handleDelete={()=>{setMagicStream(undefined)}} />
                                                        
                                        </div>) }
                                        
                                        { flow && (
                                            <div style={{
                                                width: 'calc(100% - 0px)',
                                                overflow: 'auto', // Prevents overflow from the Idea section
                                                margin: '0px 20px 20px 0px' 
                                            }}>
                                                <Idea 
                                                    isMobile={parent.isMobile}
                                                    title={'Output'} 
                                                    value={flow} 
                                                    loading={isLoading} 
                                                    outputCard 
                                                    card 
                                                    placeholder={'EMPTY'} 
                                                    handleDelete={()=>{setFlow(undefined)}} 
                                                    handleNewCard={()=>{
                                                        axios.post(
                                                            `${azure_function_url}/LLMModel?command=context_idea_update&context=${dreamContext}`,
                                                            {
                                                                id: uuidv4(),
                                                                dream: dream,
                                                                idx: cards.length + 1,
                                                                title: 'Light bulb moment',
                                                                content: marked(flow),
                                                            },
                                                            {
                                                                headers: {
                                                                    'Authorization': parent.bearerToken
                                                                }
                                                            }
                                                        ).then(async res => {
                                                            loadIdeasList();
                                                        })
                                                    }}
                                                />
                                            </div>) }
                                        { !isLoading && (
                                        <div 
                                            style={{ 
                                                flex: 1, 
                                                // overflowY: 'auto',
                                            }}
                                        > 
                                            {parent.isMobile ? 
                                            <>
                                            <List
                                                style={{ 
                                                    width:'100%',
                                                    height: 'calc(100% - 60px)',
                                                    // height: 'calc(100% - 160px)',
                                                    overflow: 'auto',
                                                    position: 'absolute',

                                                    flexGrow: 1
                                                }}
                                                itemLayout="vertical"
                                                loading={isLoadingChat.state}
                                                dataSource={cards.map((x,i)=>{return {...x, key:i}})}
                                                renderItem={(card: any, index : number) => (
                                                    <>
                                                        <br></br>
                                                        <List.Item 
                                                            key={index}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row', // Horizontal layout
                                                                borderBottom: index === cards.length - 1 ? 'none' : '1px solid black',
                                                                alignItems: 'flex-start' // Align items at the start of the flex container
                                                            }}
                                                        >
                                                            <Idea 
                                                                key={'id'+card.id+index}
                                                                id={card.id} 
                                                                title={card.title} 
                                                                value={card.content} 
                                                                loading={card.loading === 1}
                                                                streamid={card.streamid}
                                                                checked={card.checked}
                                                                setChecked={(ch:boolean)=>{card.checked=ch}}
                                                                setContent={(ct:any)=> {card.content=ct}}
                                                                card 
                                                                newCard={card.newCard}
                                                                idx={index}
                                                                dream={dream}
                                                                context={dreamContext}
                                                                placeholder={ card.prompt } 
                                                                onChange={((val: string) => {
                                                                    if(val !== card.content){
                                                                        console.log('set card')
                                                                        // setCards(cards => cards.map(_c => {if(_c.id === card.id) { _c.content = val } return _c; }))
                                                                    }
                                                                })} 
                                                                handleDelete={loadIdeasList}
                                                                user={parent.user}
                                                                bearerToken={parent.bearerToken}
                                                                isMobile={parent.isMobile}
                                                            />
                                                        </List.Item>     
                                                    </>
                                                )}
                                            />
                                            </>
                                            :
                                            <>
                                            <br></br>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="droppableCards">
                                                {(provided) => (
                                                    <Row {...provided.droppableProps} ref={provided.innerRef} gutter={[16, 16]}>
                                                    {cards.map((card, index) => (

                                                        <Draggable key={card.id} draggableId={card.id} index={index}>
                                                        {(provided) => (
                                                            <Col key={'col'+card.id+index} className='custom-idea' span={8} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <Idea 
                                                                    key={'id'+card.id+index}
                                                                    id={card.id} 
                                                                    title={card.title} 
                                                                    value={card.content} 
                                                                    loading={card.loading === 1}
                                                                    streamid={card.streamid}
                                                                    card 
                                                                    checked={card.checked}
                                                                    setChecked={(ch:boolean)=>{card.checked=ch}}
                                                                    setContent={(ct:any)=> {card.content=ct}}
                                                                    newCard={card.newCard}
                                                                    idx={index}
                                                                    dream={dream}
                                                                    context={dreamContext}
                                                                    placeholder={ card.prompt } 
                                                                    onChange={((val: string) => {
                                                                        if(val !== card.content){
                                                                            console.log('set card')
                                                                            card.content = val;
                                                                            // setCards(cards => cards.map(_c => {if(_c.id === card.id) { _c.content = val } return _c; }))
                                                                        }
                                                                    })} 
                                                                    handleDelete={loadIdeasList}
                                                                    user={parent.user}
                                                                    bearerToken={parent.bearerToken}
                                                                    isMobile={parent.isMobile}
                                                                    save={card.vec_id === null}
                                                                />
                                                            </Col>
                                                        )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                    </Row>
                                                )}
                                                </Droppable>
                                            </DragDropContext>
                                            </>}
                                        </div>
                                        )}
                                    </div>
                                }].concat(['OWNER', 'ADMIN'].indexOf(parent.contextList.concat({ id:parent.user.id, permission: 'OWNER'}).filter((x:any)=>x.id===dreamContext)[0]?.permission) > -1? [
                                {
                                    label: (
                                        <span>
                                            <KeyOutlined />
                                            Permissions
                                        </span>
                                        ),
                                    key: 'permissions',
                                    children: 
                                        <>
                                            <div style={{
                                                width:'100%',                                                
                                                display: 'flex', 
                                                boxSizing: 'border-box',
                                            
                                                borderRadius: '15px 15px', 
                                                border: '1px solid rgba(0, 0, 0, 0.25)', 
                                                margin: '0px 10px 10px 0px' 
                                            }}>
                                                <Form 
                                                    style={{
                                                        width: '100%', 
                                                        display: 'flex', 
                                                        flexDirection: 'row', 
                                                        margin: '10px',
                                                    }}
                                                >
                                                    <Form.Item
                                                        label="Context"
                                                        // noStyle
                                                        style={{
                                                            width:'100%',
                                                            marginBottom: '0px'
                                                        }}
                                                    >
                                                        <Select
                                                            value={dreamContext}
                                                            onChange={(value)=>{
                                                                if(value){
                                                                    axios.post(
                                                                        `${azure_function_url}/LLMModel?command=context_dream_update_context&context=${dreamContext}`,
                                                                        {
                                                                            id: dream,
                                                                            context: value
                                                                        },
                                                                        {
                                                                            maxContentLength: Number.POSITIVE_INFINITY,
                                                                            headers: {
                                                                                'Authorization': parent.bearerToken
                                                                            }
                                                                        }
                                                                    ).then(x => {
                                                                        console.log(x);
                                                                    }).catch(err => {console.log(err);message.error(err.toString())})
                                                                }
                        
                                                            }}
                                                            options={
                                                                contextList.map(x=> {
                                                                    return {
                                                                        value: x.id,
                                                                        label: x.name
                                                                    }})
                                                        }
                                                        />
                                                </Form.Item>
                                                </Form>
                                            </div>
                        
                                            {parent.user.id !== dreamContext && <Employees user={parent.user} bearerToken={parent.bearerToken} entityId={dreamContext} />}
                                        </>
                                }] : [])} 
                            />                            
                        </Card>
                    </div>
                </div>

                <Modal 
                    width={1500} 
                    open={openModal} 
                    closable={true} 
                    onCancel={() => setOpenModal(false)} 
                    okText={'Copy'}
                    onOk={()=> {
                        copyHtmlUsingExecCommand(renderMarkdown(cards.map(x=>`\n# ${x.title} \n ${x.content}`).join('\n<br/>\n')));
                        setOpenModal(false);
                    }}>
                    <div dangerouslySetInnerHTML={{ __html: renderMarkdown(cards.map(x=>`\n# ${x.title} \n ${x.content}`).join('\n<br/>\n')) }} ></div>
                </Modal>

                <FloatButton.Group
                    type="default"
                    style={{ left: 7, bottom: 35, width: '40px' }}
                >
                    <FloatButton tooltip={<div>Menu</div>} icon={openMenu ? <MenuFoldOutlined  /> : <MenuUnfoldOutlined />} onClick={()=> {setOpenMenu(!openMenu);}} />
                </FloatButton.Group>
                <FloatButton.Group
                    type="default"
                    style={{ right: 7, bottom: 35 }}
                >
                    <FloatButton 
                        tooltip={<div>Document</div>} 
                        icon={<FileTextOutlined  />} 
                        onClick={()=> {setOpenModal(true)}} />

                    <FloatButton 
                        tooltip={<div>New Idea</div>} 
                        icon={<PlusCircleOutlined />} 
                        onClick={()=> {setCards(cards => [
                            ...cards.concat([{'id': uuidv4(), 'title': 'Light bulb moment' }])
                        ]); }} />
                </FloatButton.Group>
            </div>
        </>
    );
}