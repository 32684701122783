import React, { useEffect, useState, useReducer } from 'react';

import { Descriptions, Badge, Typography, Card, DatePicker, Image, Modal, FloatButton, Form, Statistic, Col, Row, Button, Divider, Table, Select, Space, Alert } from 'antd';
import { RiseOutlined, FallOutlined, HomeOutlined, TeamOutlined, SendOutlined, PlusCircleOutlined, MinusCircleOutlined, CopyOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import axios from 'axios';

import { Transfer } from './transfer';
import { Deposit } from './deposit';
import { Withdraw } from './withdraw';

import { Assets } from '../deals/assets';
import { Liabilities } from '../deals/liabities';
import { ViewDeal } from '../deals/viewdeal';
import { CreateGroup } from '../deals/creategroup';
import { ViewGroups } from '../deals/viewgroups';

import { currencyAddresses, currencies, uxUpdateFrequency } from '../../utils/config';
import { zeroAddress } from '@notcentralised/notvault-sdk';

import { SIKE } from '../sike/SIKE';

const { Title, Paragraph } = Typography;

const { Column } = Table;

const truncate = (str : string) => {
    if(str)
        return str.length > 20 ? str.substring(0, 10) + "..." + str.substring(str.length - 10, str.length) : str;
    else
        return str;
}

export const TokensUX: React.FC<any> = (parent) => {

    const [openMenu, setOpenMenu] = useState(false);//!parent.isMobile);

    const [isGroupOpen, setIsGroupModalOpen] = useState(false);
    const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
    const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

    const [walletData, setWalletData] = useState<any>({});
    const [balanceData, setBalanceData] = useState<any>({});
    const [poolData, setPoolData] = useState<any>({});
    const [currency, setCurrency] = useState<string>("usdc");
    const [obligors, setObligors] = useState([zeroAddress]);
    const [obligor, setObligor] = useState(zeroAddress);
    const [groups, setGroups] = useState<any>([]);
    const [group, setGroup] = useState('0');

    const [context, setContext] = useState<string>(parent.user.id);
    const [sikeUpdateCounter, setSikeUpdateCounter] = useState(0); // Default active tab key
    const [thread, setThread] = useState('1731555022081');

    const [isProcessingRecord, setProcessingRecord] = useState(false);
    const [activeRecord, setActiveRecord] = useState<any>({amount: null, decimals: 0});
    
    const [deal, setDeal] = useState<{deal: any, type: number}>({deal: null, type: 0});

    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [activeFetchId, setActiveFetchId] = useState<NodeJS.Timer>();

    const { Option } = Select;

    const data = {
        chainId: '31337',
        // chainId: '153',
    }

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    // const [tokens] = useState<Tokens>(new Tokens(data.vault));

    // eslint-disable-next-line
    // const { address, publicKey, contactId } = data.vault.getWalletData();
    // const address = '0x574D1135a10E91006eC937eFD2b29FC5B99F18a0';
    
    const onReceive = async (values: any) => {
        console.log('---onReceive', values)
        // let idHash = values.hash_id;
        // tokens.retreive(idHash, currencyAddresses[data.chainId][data.currency]);
    };

    let loading = false;
    useEffect(() => {
        // axios.get(
        //     `${azure_function_url}/LayerC?command=balance&chainid=${data.chainId}&currency=${currency}&obligor=${obligor}`, 
        //     {
        //         headers: {
        //           'Authorization': parent.bearerToken
        //         }
        //     }).then(x => { 
        //         const data = x.data.balances;
        //         setWalletData(x.data.wallet);

        //         const obligors = [zeroAddress];
        //         for(const x of data.lockedIn)
        //             obligors.push(x.obligor);

        //         for(const x of data.lockedOut)
        //             obligors.push(x.obligor);

        //         setObligors(Array.from(new Set(obligors)));

        //         setPoolData({ decimals: data.decimals, lockedIn: data.lockedIn, lockedOut: data.lockedOut, locked: data.lockedIn.concat(data.lockedOut).map((x: any, i: number) => { x.key=i; return x }) });

        //         setBalanceData({                         
        //             balance: BigInt(data.balance) / BigInt(data.decimals),
        //             privateBalance: BigInt(data.privateBalance) / BigInt(data.decimals), 
        //             lockedIn: BigInt(data.lockedIn.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? BigInt(currentValue.amount) : BigInt(0)), BigInt(0))) / BigInt(data.decimals), 
        //             lockedOut: BigInt(data.lockedOut.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? BigInt(currentValue.amount) : BigInt(0)), BigInt(0))) / BigInt(data.decimals)
        //         });
        //     });

        axios.get(
            `${azure_function_url}/LayerC?command=list_group&chainid=${data.chainId}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(x => { 
                setGroups([{id: '0', name: 'Personal'}].concat(x.data));
                console.log(x)
            });

        if(activeFetchId !== undefined)
            clearInterval(activeFetchId);

        const id = setInterval(() => {

            if(!loading){
                axios.get(
                    `${azure_function_url}/LayerC?command=balance&chainid=${data.chainId}&currency=${currency}&obligor=${obligor}&type=all&groupId=${group}`, 
                    {
                        headers: { 'Authorization': parent.bearerToken }
                    })
                    .then(x => { 
                        const data = x.data.balances;

                        setWalletData(x.data.wallet);

                        const obligors = [zeroAddress];
                        for(const x of data.lockedIn)
                            obligors.push(x.obligor);

                        for(const x of data.lockedOut)
                            obligors.push(x.obligor);

                        setObligors(Array.from(new Set(obligors)));

                        setPoolData({ decimals: data.decimals, lockedIn: data.lockedIn, lockedOut: data.lockedOut, locked: data.lockedIn.concat(data.lockedOut).map((x: any, i: number) => { x.key=i; return x }) });

                        setBalanceData({                         
                            balance: BigInt(data.balance) / BigInt(data.decimals),
                            privateBalance: BigInt(data.privateBalance) / BigInt(data.decimals), 
                            lockedIn: BigInt(data.lockedIn.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? BigInt(currentValue.amount) : BigInt(0)), BigInt(0))) / BigInt(data.decimals), 
                            lockedOut: BigInt(data.lockedOut.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? BigInt(currentValue.amount) : BigInt(0)), BigInt(0))) / BigInt(data.decimals)
                        });

                        loading = false;
                    }).catch(err=> { loading = false });
            }
        
        }, uxUpdateFrequency); 

        setActiveFetchId(id);

        // try{
        //     data.vault.setValue('A', 'B').then((txTest : any) => {
        //         console.log('---- TX TEST')
        //         console.log(txTest)
        //     })
        // }
        // catch{}

        // return () => clearInterval(id); 
    // eslint-disable-next-line        
    }, [obligor, group]);

    const copyHtmlUsingExecCommand = (htmlContent: string) => {
        const container = document.createElement('div');
        container.innerHTML = htmlContent;
        container.style.position = 'fixed'; // Avoids scrolling to bottom
        document.body.appendChild(container);
        
        const ww = window.getSelection();
        ww?.removeAllRanges();
        const range = document.createRange();
        range.selectNode(container);
        ww?.addRange(range);
      
        try {
          document.execCommand('copy');
          console.log('Content copied');
        } catch (err) {
          console.error('Failed to copy', err);
        }
      
        document.body.removeChild(container);
    }
    
    return (
    <>
    <div style={{
        position: 'relative', 
        display: 'flex', 
        justifyContent: 'center', 

        height: 'calc(100vh - 65px)'
    }}>

        <div 
            className='custom-card' 
            style={{
                display: openMenu && parent.isMobile ? 'none' :  undefined,
                width:  '100%', 
                height: '100%',
                marginRight: parent.isMobile ? undefined :  '55px', 
                marginLeft: parent.isMobile || openMenu ? undefined:  '55px',
            }}
        >
            <Card 
                bordered={false} 
                className='custom-card'
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >

                <Row 
                    gutter={20} 
                    style={{
                        // width:'calc(100%)', 
                        // paddingRight: '25px', 
                        height: 'calc(100vh - 90px)'
                        
                    }}
                >
                    <Col 
                        style={{
                            overflowY: 'auto', 
                            height: 'calc(100vh - 100px)',
                            paddingLeft: '30px',
                            paddingRight: '30px'
                        }} 
                        span={14}
                    >
                        <Descriptions bordered>
                            <Descriptions.Item style={{textAlign:'left'}} label="Account" span={3}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Search to Select"
                                    optionFilterProp="label"
                                    value={group}
                                    options={groups?.map((x:any)=>{ return { value: x.id, label: x.name }})}
                                    onChange={(value) => {
                                        setGroup(value);
                                    }}
                                />
                                </div>
                            </Descriptions.Item>
                            <Descriptions.Item style={{textAlign:'left'}} label="Wallet Address" span={3}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Space.Compact 
                                        style={{ 
                                            display: 'flex',
                                            flexGrow: 1,
                                            marginBottom: 0,
                                            
                                        }}
                                    >
                                        <Badge style={{top:'5px'}} status="success" text={walletData.address} />
                                    
                                    <Button
                                        
                                        type="primary" 
                                        icon={<CopyOutlined />} 
                                        onClick={() => { 
                                            copyHtmlUsingExecCommand(walletData.address)
                                        }}
                                        style={{ 
                                            color: 'rgba(0, 0, 0, 0.75)',
                                            backgroundColor: 'transparent'
                                        }}
                                    />
                                    </Space.Compact>
                                </div>
                            </Descriptions.Item>
                            <Descriptions.Item  style={{textAlign:'left'}}  label="Network" span={3}>
                                <Badge status="success" text={data.chainId === '5' ? 'Goerli' : data.chainId === '11155111' ? 'Sepolia' : data.chainId === '296' ?  'Hedera Testnet' : data.chainId === '153' ?  'Redbelly Testnet' : 'Localhost'} />
                            </Descriptions.Item>
                            <Descriptions.Item style={{textAlign:'left'}} label="Public Key" span={3}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Space.Compact 
                                        style={{ 
                                            display: 'flex',
                                            flexGrow: 1,
                                            marginBottom: 0,
                                            
                                        }}
                                    >
                                        <Badge style={{top:'5px'}} status="success" text={walletData.publicKey?.substring(0, 90)} />
                                    
                                    <Button
                                        
                                        type="primary" 
                                        icon={<CopyOutlined />} 
                                        onClick={() => { 
                                            copyHtmlUsingExecCommand(walletData.publicKey)
                                        }}
                                        style={{ 
                                            color: 'rgba(0, 0, 0, 0.75)',
                                            backgroundColor: 'transparent'
                                        }}
                                    />
                                    </Space.Compact>
                                </div>
                            </Descriptions.Item>
                        </Descriptions>

                        {/* <br />
                        <br /> 
                        <br />

                        <Row gutter={16}>
                            <Col span={12}>
                                <Select
                                    size="large"
                                    style={{ width: '100%' }}
                                    defaultValue={'usdc'}
                                    onChange={(val:string) => { setCurrency(val); }}
                                    optionLabelProp="label"
                                >
                                    {
                                        Object.keys(currencies).map((currency, i) => 
                                            <Option value={currency} label={currencies[currency].label} key={i+1}>
                                                <Space>
                                                    <span role="img" aria-label={currency}>
                                                        <Image height={20} src={currencies[currency].url} ></Image>
                                                    </span>
                                                    {currencies[currency].label}
                                                </Space>
                                            </Option>
                                        )
                                    }
                                </Select>
                            </Col>
                            <Col span={12}>
                                <Select
                                    size="large"
                                    style={{ width: '100%' }}
                                    defaultValue={zeroAddress}
                                    onChange={(val:string) => { setObligor(val); }}
                                    optionLabelProp="label"
                                >
                                    {
                                        obligors.map((_obligor, i) => 
                                            <Option value={_obligor} label={_obligor} key={i+1}>
                                                <Space>
                                                    {_obligor}
                                                </Space>
                                            </Option>
                                        )
                                    }
                                </Select>
                            </Col>
                        </Row>
                         */}
                        <br />
                        <br />
                        <br />
                        

                        <Row gutter={16}>
                            <Col span={6}>
                                <Statistic
                                    prefix={<Image preview={false} height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                                    title="Public Balance"
                                    value={balanceData.balance ? balanceData.balance : 0}
                                    precision={2}
                                />
                            </Col>
                            <Col span={6}>
                                <Statistic
                                    prefix={<Image preview={false} height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                                    title="Private Balance"
                                    value={balanceData.privateBalance ? balanceData.privateBalance : 0}
                                    precision={2}
                                />
                            </Col>
                            <Col span={6}>
                                <Statistic
                                    prefix={<Image preview={false} height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                                    title="Payables"
                                    value={balanceData.lockedOut ? balanceData.lockedOut : 0}
                                    precision={2}
                                />
                            </Col>
                            <Col span={6}>
                                <Statistic
                                    prefix={<Image preview={false} height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                                    title="Receivables"
                                    value={balanceData.lockedIn ? balanceData.lockedIn : 0}
                                    precision={2}
                                />
                            </Col>
                        </Row>

                        <br />
                        <br />
                        <br />

                        {/* <Row gutter={16}>
                            <Col span={24}>
                                <ViewGroups isMobile={parent.isMobile} bearerToken={parent.bearerToken} user={parent.user} currency={currency} obligor={obligor} chainId={data.chainId} walletData={walletData} poolData={poolData} />
                            </Col>
                        </Row> */}

                        <Row gutter={16}>
                            <Col span={24}>
                                <Assets isMobile={parent.isMobile} bearerToken={parent.bearerToken} user={parent.user} currency={currency} groupId={group} obligor={obligor} chainId={data.chainId} walletData={walletData} poolData={poolData} />
                            </Col>
                        </Row>

                        <Row gutter={16}>

                            <Col span={24}>
                                <Liabilities isMobile={parent.isMobile} bearerToken={parent.bearerToken} user={parent.user} currency={currency} groupId={group}  obligor={obligor} chainId={data.chainId} walletData={walletData} poolData={poolData} />
                            </Col>
                        </Row>
                        
                        <br />
                        <Divider orientation="left">Cashflows</Divider>
                        { isProcessingRecord ?
                            <Alert
                                
                                message={`Please confirm the processing of transfer: ${( activeRecord.amount / poolData.decimals).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                type="warning"
                                action={
                                    <Space direction="vertical">
                                        
                                        <Button size="small" type="primary" danger onClick={async ()=> { 
                                            axios.post(
                                                `${azure_function_url}/LayerC?command=retreive_amount&chainid=${data.chainId}&currency=${currency}&obligor=${obligor}`, 
                                                {
                                                    idhash: activeRecord.idHash
                                                },
                                                {
                                                    headers: { 'Authorization': parent.bearerToken }
                                                })
                                                .then(x => {
                                                    setProcessingRecord(false);
                                                });
                                        }}>
                                            Process
                                        </Button>
                                        <Button size="small" danger type="dashed" onClick={() => setProcessingRecord(false) }>
                                            Cancel
                                        </Button>
                                    </Space>
                                }
                            />
                        :
                            <></>
                        }
                        <Form
                            name="receive_amount"
                            onFinish={onReceive}
                            initialValues={{}}
                            labelCol={{ span: 8 }}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Table 
                                        dataSource={poolData.locked ? poolData.locked.filter((x : any) => x.active) : []}
                                        pagination={{hideOnSinglePage: true}}
                                    >
                                        <Column
                                            title="Type"
                                            key="type"
                                            render={(_: any, record: any) => (
                                                record.sender.toLowerCase() !== walletData.address.toLowerCase() ? <RiseOutlined twoToneColor="#52c41a" /> : <FallOutlined twoToneColor="#eb2f96" />
                                            )}
                                        />
                                        <Column
                                            title="Counterpart"
                                            key="couterpart"
                                            render={(_: any, record: any) => (
                                                record.sender.toLowerCase() === walletData.address.toLowerCase() ? truncate(record.owner) : truncate(record.sender)
                                            )}
                                        />
                                        {/* <Column
                                            title="Obligor"
                                            key="obligor"
                                            render={(_: any, record: any) => (
                                                truncate(record.obligor)
                                            )}
                                        /> */}
                                        <Column
                                            title="Amount"
                                            key="amount"
                                            render={(_: any, record: any) => (
                                                <><Image preview={false} height={15} style={{marginTop: "-2px"}} src={currencies[currency].url} /> {(record.amount / poolData.decimals).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</>
                                            )}
                                        />
                                        <Column
                                            title="Created"
                                            key="created"
                                            render={(_: any, record: any) => (
                                                <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.created)} />
                                            )}
                                        />
                                        <Column
                                            title="Unlock"
                                            key="unlock"
                                            render={(_: any, record: any) => (
                                                <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.sender.toLowerCase() === walletData.address.toLowerCase() ? record.unlock_sender : record.unlock_receiver)} />
                                            )}
                                        />
                                        <Column
                                            title="Contract"
                                            key="contract"
                                            render={(_: any, record: any) => (
                                                <Button 
                                                    type="default" 
                                                    block
                                                    // disabled={!record.active || (record.owner.toLowerCase() === walletData.address.toLowerCase() ? record.unlock_receiver * 1000 > Date.now() : record.unlock_sender * 1000 > Date.now())}                                
                                                    onClick={() => { 
                                                        console.log(record);
                                                        
                                                        axios.get(
                                                            `${azure_function_url}/LayerC?command=deal_get&chainid=${data.chainId}&id=${record.deal_id}`, 
                                                            {
                                                                headers: { 'Authorization': parent.bearerToken }
                                                            })
                                                            .then(x => {
                                                                setDeal({deal: x.data, type: 1}); 
                                                            });

                                                        forceUpdate();
                                                     }}
                                                >
                                                    {record.deal_id }
                                                </Button>
                                            )}
                                        />
                                        <Column
                                            title="Action"
                                            key="action"
                                            render={(_: any, record: any) => (
                                                <Button 
                                                    type="default" 
                                                    block
                                                    disabled={!record.active || (record.owner.toLowerCase() === walletData.address.toLowerCase() ? record.unlock_receiver * 1000 > Date.now() : record.unlock_sender * 1000 > Date.now())}                                
                                                    onClick={() => { setActiveRecord(record); setProcessingRecord(true); }}
                                                >
                                                    {record.owner.toLowerCase() === walletData.address.toLowerCase() ? 'Redeem': 'Cancel' }
                                                </Button>
                                            )}
                                        />
                                    </Table>
                                </Col>
                            </Row>
                        </Form>

                    </Col>

                    <Col span={10}>
                        <Card bordered={false}>
                            <div
                                style={{
                                    height:'calc(100vh - 100px)', 
                                    // zIndex: 1000
                                }}
                            >
                            <SIKE 
                                bearerToken={parent.bearerToken} 
                                user={parent.user} 
                                context={context} 
                                thread={thread} 
                                permission={parent.permission} 
                                updateCounter={sikeUpdateCounter}
                                isMobile={parent.isMobile}

                                prompt={(input: string) => {return {
                                    json: [
                                        { "history": 5 },
                                        { "context": context },
                                        { "thread": thread },
                                        { "model": "gpt-4" },
                                        {
                                            "prompt": "first",
                                            "temperature": 0,
                                            "instructions": [
    `You are a helpful payments agent
    ---
    Use the report to assist the user by responding to the question / statement in a succinct way:
    ${input}
    `
                                            ]
                                        }
                                    ],
                                    input: input,
                                    agent_reference: 'custom'
                                }}}
                                initMessage={`Hello ${parent.user.data.personal.name.firstName}, I am here help you unlock the market. What shall we investigate?`}  
                            />
                            </div>
                        </Card>
                    </Col>
                </Row>   
            </Card>
                    
        </div>
        
    </div>
    <FloatButton.Group
            type="default"
            style={{ left: 7, bottom: 100, width: '40px' }}
            icon={<HomeOutlined />}
        >
            <FloatButton tooltip={<div>Groups</div>} icon={<TeamOutlined />} onClick={()=> {setIsGroupModalOpen(true);}} />
            <FloatButton tooltip={<div>Private Transfer</div>} icon={<SendOutlined />} onClick={()=> {setIsTransferModalOpen(true);}} />
            <FloatButton tooltip={<div>Deposit</div>} icon={<PlusCircleOutlined />} onClick={()=> {setIsDepositModalOpen(true);}} />
            <FloatButton tooltip={<div>Withdraw</div>} icon={<MinusCircleOutlined />} onClick={()=> {setIsWithdrawModalOpen(true);}} />
        </FloatButton.Group>

        
        <Modal title="Group Account" closable={false} open={isGroupOpen} footer={null}>
            <CreateGroup 
                bearerToken={parent.bearerToken}
                user={parent.user}
                walletData={walletData}
                balanceData={balanceData}
                chainId={data.chainId}
                obligor={obligor}
                currency={currency}
                tokenAddress={currencyAddresses[data.chainId][currency]}
                closeModal={() => {setIsGroupModalOpen(false);}}
            />
        </Modal>
    
        <Modal title="Transfer" closable={false} open={isTransferModalOpen} footer={null}>
            <Transfer 
                bearerToken={parent.bearerToken}
                user={parent.user}
                walletData={walletData}
                balanceData={balanceData}
                chainId={data.chainId}
                obligor={obligor}
                currency={currency}
                tokenAddress={currencyAddresses[data.chainId][currency]}
                closeModal={() => {setIsTransferModalOpen(false);}}
            />
        </Modal>

        <Modal title="Deposit" closable={false} open={isDepositModalOpen} footer={null}>
            <Deposit 
                balanceData={balanceData}
                tokenAddress={currencyAddresses[data.chainId][currency]}
                closeModal={() => {setIsDepositModalOpen(false);}}
            />
        </Modal>

        <Modal title="Withdraw" closable={false} open={isWithdrawModalOpen} footer={null}>
            <Withdraw 
                balanceData={balanceData}
                tokenAddress={currencyAddresses[data.chainId][currency]}
                closeModal={() => {setIsWithdrawModalOpen(false);}}
            />
        </Modal>

        <Modal title="View Contract" closable={false} open={deal.type > 0} footer={null}>
            <ViewDeal 
                bearerToken={parent.bearerToken}
                tokenAddress={deal.deal?.denomination}
                deal={deal.deal}
                increase={false}
                closeModal={() => {setDeal({deal: null, type: 0});}}
            />
        </Modal>
    </>
    );
}